import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subcategorytotal: [],
  subcategoryicon: "",
  subcategorythumbnail: "",
  subcategorybanner: "",
  subcategorydesktop: "",
  issciconLoading: true,
  isscthumbnailLoading: true,
  isscbannerLoading: true,
  isscdesktopLoading: true,
  isLoading: true,
  isSubCatLoading: true,
  deleteSubCatLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "subCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);
      return resp.data.SubCategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const getSubCategoryById = createAsyncThunk(
  "getSubCategory/getSubCategoryById",
  async (catid, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/${catid}`;
      const resp = await axios(url);
      return resp.data.subcategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const subCategoryPost = createAsyncThunk(
  "subCategory/subCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/new`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Sub Category Not create");
    }
  }
);

export const subCategoryUpdate = createAsyncThunk(
  "subCategory/subCategoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/${formData.subcatid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

export const subCategoryDelete = createAsyncThunk(
  "subCategory/subCategoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/subcategory/${id}`;
      const resp = await axios.delete(url, id, config);

      const myreturn = {
        success: resp.data.success,
        id: id,
      }
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Not create");
    }
  }
);

export const subCaticonpost = createAsyncThunk(
  "subCaticon/subCaticonpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/icon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Icon Not create");
    }
  }
);


export const subCatThumbnailpost = createAsyncThunk(
  "subCatThumbnail/subCatThumbnailpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/subcategory/thumbnail`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Thumbnail Not create");
    }
  }
);
export const subCatnewimgpost = createAsyncThunk(
  "subCatThumbnail/subCatnewimgpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/subcategory/newimage`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory Thumbnail Not create");
    }
  }
);
export const subCatBannerpost = createAsyncThunk(
  "subCatBanner/subCatBannerpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/banner`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory banner Not create");
    }
  }
);

export const subCatDesktoppost = createAsyncThunk(
  "subCatDesktop/subCatDesktoppost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/subcategory/desktopicon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("subcategory desktopIcon Not create");
    }
  }
);

export const validatesubcatSlugUrl = createAsyncThunk(
  "subcategory/validatesubcatSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    }
    try {
      const url = `${Baseurl}/api/v1/subcategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);


const SubCategorySlice = createSlice({
  name: "subCategoryAll",
  initialState,
  reducers: {
    updateSubcatIcon(state, action) {
      state.subcategoryicon = action.payload;
      state.issciconLoading = false;
    },
    updateSubcatThumb(state, action) {
      state.subcategorythumbnail = action.payload;
      state.isscthumbnailLoading = false;
    },
    updateSubcatBanner(state, action) {
      state.subcategorybanner = action.payload;
      state.isscbannerLoading = false;
    },
    updateSubcatDesktop(state, action) {
      state.subcategorydesktop = action.payload;
      state.isscdesktopLoading = false;
    },
    deleteSubcategory(state, action) {
      state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload);
    },
    resetSubCatImage(state) {
      state.subcategoryicon = "";
      state.subcategorybanner = "";
      state.subcategorydesktop = "";
      state.subcategorythumbnail = "";
      state.isscbannerLoading = true;
      state.isscdesktopLoading = true;
      state.issciconLoading = true;
      state.isscthumbnailLoading = true;
    }
  },
  extraReducers: {

    [getSubCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubCategory.fulfilled]: (state, action) => {
      state.subcategorytotal = action.payload;

      state.isLoading = false;
      state.isSubCatLoading = false;
    },
    [getSubCategory.rejected]: (state, action) => {
      state.isLoading = true;
    },


    [getSubCategoryById.pending]: (state) => {
      state.isLoading = true;
    },


    [getSubCategoryById.fulfilled]: (state, action) => {
      state.subcategorytotal = action.payload;


      state.grocerysubcategory = action.payload.filter((subcategory) => subcategory.categoryId === action.payload)
      state.isLoading = false;
      state.isSubCatLoading = false;
    },
    [getSubCategoryById.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [subCategoryPost.pending]: (state) => {
      state.isSubCatLoading = true;
    },

    [subCategoryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory];
      }
      state.isSubCatLoading = false;
    },

    [subCategoryPost.rejected]: (state, action) => {
      state.isSubCatLoading = true;
    },



    [subCategoryUpdate.pending]: (state) => {
      state.isSubCatLoading = true;
    },

    [subCategoryUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.subcategory._id);
        state.subcategorytotal = [...state.subcategorytotal, action.payload.subcategory];
        state.subcategoryicon = "";
        state.subcategorybanner = "";
        state.subcategorydesktop = "";
        state.subcategorythumbnail = "";
        state.isscbannerLoading = true;
        state.isscdesktopLoading = true;
        state.issciconLoading = true;
        state.isscthumbnailLoading = true;
      }
      state.isSubCatLoading = false;
    },

    [subCategoryUpdate.rejected]: (state, action) => {
      state.isSubCatLoading = true;
    },



    [subCategoryDelete.pending]: (state) => {
      state.deleteSubCatLoading = true;
    },

    [subCategoryDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorytotal = state.subcategorytotal.filter((subcategory) => subcategory._id !== action.payload.id);
      }
      state.deleteSubCatLoading = false;
    },

    [subCategoryDelete.rejected]: (state, action) => {
      state.deleteSubCatLoading = true;
    },



    [subCaticonpost.pending]: (state) => {
      state.issciconLoading = true;
    },

    [subCaticonpost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategoryicon = action.payload.icons;
      }
      state.issciconLoading = false;
    },

    [subCaticonpost.rejected]: (state, action) => {
      state.issciconLoading = true;
    },

    [subCatThumbnailpost.pending]: (state) => {
      state.isscthumbnailLoading = true;
    },

    [subCatThumbnailpost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorythumbnail = action.payload.thumbnails;
      }
      state.isscthumbnailLoading = false;
    },

    [subCatThumbnailpost.rejected]: (state, action) => {
      state.isscthumbnailLoading = true;
    },

    [subCatBannerpost.pending]: (state) => {
      state.isscbannerLoading = true;
    },
    [subCatBannerpost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorybanner = action.payload.banners;
      }
      state.isscbannerLoading = false;
    },

    [subCatBannerpost.rejected]: (state, action) => {
      state.isscbannerLoading = true;
    },

    [subCatDesktoppost.pending]: (state) => {
      state.isscdesktopLoading = true;
    },

    [subCatDesktoppost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorydesktop = action.payload.desktopIcon;
      }
      state.isscdesktopLoading = false;
    },

    [subCatDesktoppost.rejected]: (state, action) => {
      state.isscdesktopLoading = true;
    },
    [subCatnewimgpost.pending]: (state) => {
      state.isscdesktopLoading = true;
    },

    [subCatnewimgpost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.subcategorydesktop = action.payload.desktopIcon;
        state.subcategorythumbnail = action.payload.thumbnails;
        state.subcategoryicon = action.payload.icons;
      }
      state.isscdesktopLoading = false;
      state.isscthumbnailLoading = false;
      state.issciconLoading = false;
    },

    [subCatnewimgpost.rejected]: (state, action) => {
      state.isscdesktopLoading = true;
    },
  },
});

export const { updateSubcatIcon, updateSubcatThumb, updateSubcatBanner, updateSubcatDesktop, deleteSubcategory, resetSubCatImage } = SubCategorySlice.actions;
export default SubCategorySlice.reducer;