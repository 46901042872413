import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import { Modal, ModalHeader } from "reactstrap";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { productUpdate } from "../../../redux/product/ProductSlice";

const ListProduct = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [filterproducts, setFilterProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);
  const [temploading, setTempLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const url = `${Baseurl}/api/v1/product/all`;
      const fetchproducts = await axios.get(url);
      try {
        if (fetchproducts.data.success === true) {
          setTempLoading(false);
          const prod = fetchproducts.data.products;
          const reverlist = [...prod].sort((a, b) =>
            a.ItemName > b.ItemName ? 1 : -1
          );
          setProducts(reverlist);
        }
      } catch (error) {}
    };
    fetchProduct();
  }, []);

  useEffect(() => {
    if (search !== "") {
      const filterproduct = products.filter(
        (product) =>
          product.ItemName.toLowerCase().match(search.toLowerCase()) ||
          product.Category.toLowerCase().match(search.toLowerCase())
      );
      setFilterProducts(filterproduct);
    } else {
      const filterproduct = products;
      setFilterProducts(filterproduct);
    }
  }, [products, search]);

  const cancelClick = (e, product) => {
    setCancelModel(true);
    setSelectProduct(product);
  };

  const deleteClick = async (e, id) => {
    // const data = await dispatch(groceryDel(selectProduct._id));
    setCancelModel(false);
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        HotProducts: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };
  const statusRecommendsChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        Recommends: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };
  const statusTrendingChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        Trending: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };
  const statusOffersChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        Offers: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };
  const statusTodayFeatureChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        TodayFeature: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };
  const statusShowChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        productId: data._id,
        Show: value,
      };
      const updateproducts = await dispatch(productUpdate(formData));

      if (updateproducts.payload.success === true) {
        const prod = updateproducts.payload.product;

        const updatepro = products.filter(
          (product) => product._id !== prod._id
        );
        const prods = [...updatepro, prod];

        const reverlist = [...prods].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProducts(reverlist);
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ItemName",
      headerName: " PRODUCT NAME",
      width: 300,
      headerAlign: "center",
    },

    {
      field: "thumbnail",
      headerName: "IMAGE",
      width: 200,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto my-1"
              src={params.row.options[0].thumbnail}
              height={100}
              alt="thumnail"
            />
          </>
        );
      },
    },
    {
      field: "ACTION",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-3">
            <Link to={`/admin/updategrocerynew/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },

    {
      field: "Show",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.Show}
                  checked={params.row.Show}
                  onChange={(e) => statusShowChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "Hot Product",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.HotProducts}
                  checked={params.row.HotProducts}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "Recommends",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.Recommends}
                  checked={params.row.Recommends}
                  onChange={(e) => statusRecommendsChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "Trending Product",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.Trending}
                  checked={params.row.Trending}
                  onChange={(e) => statusTrendingChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "Offers Product",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.Offers}
                  checked={params.row.Offers}
                  onChange={(e) => statusOffersChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "Today Feature",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.TodayFeature}
                  checked={params.row.TodayFeature}
                  onChange={(e) => statusTodayFeatureChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "ACTION.",
      width: 180,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto my-2">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          {temploading ? (
            <>
              <div className="d-flex justify-content-center loadingMain">
                <h6 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                  Product is being fetched
                </h6>
                <BeatLoader
                  color={"#36d7b7"}
                  loading={temploading}
                  size={10}
                  className="mt-2 mx-2"
                />
              </div>
            </>
          ) : (
            <>
              <div className="card m-4">
                <div className="card-footer d-flex justify-content-between">
                  <h5>Products List</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Control
                        type="text"
                        placeholder="Search products"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div
                  style={{ height: 1760, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={filterproducts}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={15}
                    rowHeight={50}
                    getRowHeight={() => "auto"}
                    rowsPerPageOptions={[20]}
                    // checkboxSelection
                  />
                </div>
              </div>
              <Modal
                size="md"
                isOpen={cancelmodel}
                toggle={() => setCancelModel(!cancelmodel)}
              >
                <ModalHeader>
                  <div className=" ">
                    <div className="col-12 ">
                      <div className="col-12">
                        <h3>
                          Do You Want to <span>Delete</span>{" "}
                          {selectProduct !== "" ? (
                            <>
                              <span
                                style={{
                                  color: "#dc3545",
                                }}
                              >
                                {selectProduct.ItemName}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}{" "}
                          Product
                        </h3>
                        <div className="col-12 mt-3 ">
                          <div className="col-6 d-flex justify-content-between">
                            <Button
                              variant="contained"
                              color="error"
                              className="mx-1 px-5"
                              onClick={() => setCancelModel(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mx-1 px-5"
                              onClick={(e) => deleteClick(e)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalHeader>
              </Modal>
            </>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default ListProduct;
