import React from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { brandDelete, brandUpdate } from "../../../redux/brand/brandSlice";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Modal, ModalHeader } from "reactstrap";

const ListBrand = () => {
  const { brandtotal } = useSelector((store) => store.brandAll);

  const [loadData, setLoadData] = useState([]);
  const [selectBrand, setSelectBrand] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  useEffect(() => {
    const brand = [...brandtotal].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadData(brand);
  }, [brandtotal]);

  const dispatch = useDispatch();

  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectBrand(subcategory);
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(brandDelete(selectBrand._id));
    setCancelModel(false);
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;
    if (data) {
      const formData = {
        brandid: data._id,
        status: value,
      };
      const updateCat = await dispatch(brandUpdate(formData));
    }
  };
  const offersChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;
    if (data) {
      const formData = {
        brandid: data._id,
        offers: value,
      };
      const updateCat = await dispatch(brandUpdate(formData));
    }
  };
  const partnerChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;
    if (data) {
      const formData = {
        brandid: data._id,
        partners: value,
      };
      const updateCat = await dispatch(brandUpdate(formData));
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "BRAND NAME",
      width: 250,
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 270,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail.url}
    //           width={50}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "OFFERS",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.offers}
                  checked={params.row.offers}
                  onChange={(e) => offersChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "PARTNERS",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.partners}
                  checked={params.row.partners}
                  onChange={(e) => partnerChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "ACTION",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-2">
            <Link to={`/admin/updatebrand/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 150,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            {/* <Button variant="contained" color="error">
              DELETE
            </Button> */}
            <Button
              variant="contained"
              // onClick={(e) => deleteClick(e, cellValues.row._id)}
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card">
            <div className="mb-3 card-footer">
              <CSVLink data={loadData} onClick={() => {}}>
                <button className="btn btn-success mt-3">export data</button>
              </CSVLink>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={loadData}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowHeight={100}
                getRowHeight={() => "auto"}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>
          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectBrand !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectBrand.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Brand
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListBrand;
