import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  subCategoryPost,
  subCaticonpost,
  subCatThumbnailpost,
  subCatBannerpost,
  subCatDesktoppost,
  updateSubcatIcon,
  updateSubcatThumb,
  updateSubcatBanner,
  updateSubcatDesktop,
  subCategoryUpdate
} from "../../../redux/subCategory/subCategorySlice";

const UpdateSubCategory = () => {
  const {
    subcategoryicon,
    subcategorythumbnail,
    subcategorybanner,
    subcategorydesktop,
    issciconLoading,
    isscthumbnailLoading,
    isscbannerLoading,
    isscdesktopLoading,
  } = useSelector((store) => store.subCategoryAll);

  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [subcatId, setSubcatId] = useState("");
   const [priority, setPriority] = useState(0);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const subcatUrl = params.slugurl;

    const updatesubcat = subcategorytotal.find(
      (subcat) => subcat.slugUrl === subcatUrl
    );
    setSubcatId(updatesubcat._id);

    setNames(updatesubcat.name);
    setSlugUrl(updatesubcat.slugUrl);
    setMetatitle(updatesubcat.metaTitle);
    setMetakeyword(updatesubcat.metaKeyword);
    setMetadesc(updatesubcat.metaDesc);
    setCategory(updatesubcat.category);
    setCategoryid(updatesubcat.categoryId);
    setPriority(updatesubcat.priority);
    dispatch(updateSubcatIcon(updatesubcat.icon));
    dispatch(updateSubcatThumb(updatesubcat.thumbnail));
    dispatch(updateSubcatBanner(updatesubcat.banner));
    dispatch(updateSubcatDesktop(updatesubcat.desktopicon));

  }, []);

  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const namechange = (e) => {
    const name = e.target.value;
    setNames(name);

    let slugurl = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

    setSlugUrl(slugurl);
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (names !== "" && !isscbannerLoading && !isscbannerLoading) {
   
    const formData = {
      name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
      slugUrl: slugUrl,
      category: category,
      categoryId: categoryid,
      metaTitle: metatitle,
      metaKeyword: metakeyword,
      metaDesc: metadesc,
      priority: priority,
      icon: subcategoryicon,
      thumbnail: subcategorythumbnail,
      desktopicon: subcategorydesktop,
      banner: subcategorybanner,
      subcatid: subcatId,
    };


    const data = await dispatch(subCategoryUpdate(formData))

    if (!data) {
      alert("please check the flields");
    } else {
      if (data.payload.success) {
        alert("subCategory Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }

  } else {
    alert("please enter valid details");
  }
    
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };
  const iconChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCaticonpost({ icon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatThumbnailpost({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const bannerChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatBannerpost({ banner: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatDesktoppost({ desktopicon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const clearClick = () =>{
    setSlugUrl("");
    setNames("");
    setMetatitle("");
    setMetakeyword("");
    setMetadesc("");
    
  }
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Sub-Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Sub-Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Priority
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter  Priority"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        min={4}
                        max={150}
                        placeholder="Enter Name"
                        value={names}
                        onChange={namechange}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Slug URL *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        min={4}
                        max={150}
                        placeholder="Enter  Slug URL"
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Sub-Category Icon Image</Form.Label>
                      <Form.Control type="file" onChange={iconChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 45px * 45px
                      </p>
                      <div>
                        {issciconLoading ? (
                          <div></div>
                        ) : (
                          <img src={subcategoryicon} height={150} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Sub-Category Thumbnail Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 200px * 200px
                      </p>
                      <div>
                        {isscthumbnailLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={subcategorythumbnail}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Sub-Category Banner Image</Form.Label>
                      <Form.Control type="file" onChange={bannerChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 1050px * 320px
                      </p>
                      <div>
                        {isscbannerLoading ? (
                          <div></div>
                        ) : (
                          <img src={subcategorybanner} height={150} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Sub-Category Desktop Image</Form.Label>
                      <Form.Control type="file" onChange={desktopChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 80px * 80px
                      </p>
                      <div>
                        {isscdesktopLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={subcategorydesktop}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center ">
                  <button className="btn btn-primary me-2" type="submit">
                    Update Sub Category
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={clearClick}
                    type="reset"
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSubCategory;
