import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, } from "../../config/BaseUrl";

const initialState = {
  grocerytotal: [],
  groceryicon: "",
  grocerybanner: [],
  grocerydesktop: "",
  grocerythumb: "",
  isgroceryIconLoading: true,
  isgrocerythumbLoading: true,
  isgrocerybanLoading: true,
  isgrocerydeskLoading: true,
  isLoading: true,
  groceryLoading: true,
  grocerypacksizeLoading: true,
  delgroceyLoading: true,
};

export const getGrocery = createAsyncThunk(
  "grocery/getGrocery",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const resp = await axios(url);
      return resp.data.groceries;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const groceryPost = createAsyncThunk(
  "grocery/groceryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const groceryUpdate = createAsyncThunk(
  "grocery/groceryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/grocery/${formData.groceryid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const grocerypacksizeUpdate = createAsyncThunk(
  "grocery/groceryUpdatepacksize",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/packsize/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryDel = createAsyncThunk(
  "grocery/groceryDel",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/${id}`;
      const resp = await axios.delete(url);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryIcon = createAsyncThunk(
  "grocery/groceryIcon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/icon`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Icon Not create");
    }
  }
);
export const groceryThumbnail = createAsyncThunk(
  "grocery/groceryThumbnail",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/grocery/thumbnail`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Thumbnail Not create");
    }
  }
);

export const groceryBanner = createAsyncThunk(
  "grocery/groceryBanner",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/banner`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryBannerNew = createAsyncThunk(
  "grocery/groceryBannernew",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/bannernew`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data)
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryDesktop = createAsyncThunk(
  "grocery/groceryDesktop",

  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/desktopicon`;
      const resp = await axios.post(url, formData, config);
      console.log(resp);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Icon Not create");
    }
  }
);

export const validateGrocerySlugUrl = createAsyncThunk(
  "grocery/validateGrocerySlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    };
    try {
      const url = `${Baseurl}/api/v1/grocery/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const GrocerySlice = createSlice({
  name: "groceryAll",
  initialState,
  reducers: {
    updateGroceryIcon(state, action) {
      state.groceryicon = action.payload;
      state.isgroceryIconLoading = false;
    },
    updateGroceryThumbnail(state, action) {
      state.grocerythumb = action.payload;
      state.isgrocerythumbLoading = false;
    },
    updateGroceryBanner(state, action) {
      state.grocerybanner = action.payload;
      state.isgrocerybanLoading = false;
    },
    updateGroceryDesktop(state, action) {
      state.grocerydesktop = action.payload;
      state.isgrocerydeskLoading = false;
    },
    resetImage(state) {
      state.groceryicon = "";
      state.grocerybanner = [];
      state.grocerydesktop = "";
      state.grocerythumb = "";
      state.isgrocerybanLoading = true;
      state.isgrocerydeskLoading = true;
      state.isgroceryIconLoading = true;
      state.isgrocerythumbLoading = true;
    },
  },
  extraReducers: {
    [getGrocery.pending]: (state) => {
      state.isLoading = true;
    },
    [getGrocery.fulfilled]: (state, action) => {
      state.grocerytotal = action.payload;

      state.isLoading = false;
      state.groceryLoading = false;
    },
    [getGrocery.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [groceryPost.pending]: (state) => {
      state.groceryLoading = true;
    },

    [groceryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        state.groceryicon = "";
        state.grocerybanner = [];
        state.grocerydesktop = "";
        state.grocerythumb = "";
        state.isgrocerybanLoading = true;
        state.isgrocerydeskLoading = true;
        state.isgroceryIconLoading = true;
        state.isgrocerythumbLoading = true;

      }
      state.groceryLoading = false;
    },

    [groceryPost.rejected]: (state, action) => {
      state.groceryLoading = true;
    },

    [groceryUpdate.pending]: (state) => {
      state.groceryLoading = true;
    },

    [groceryUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.grocery._id
        );
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        state.groceryicon = "";
        state.grocerybanner = [];
        state.grocerydesktop = "";
        state.grocerythumb = "";
        state.isgroceryIconLoading = true;
        state.isgrocerythumbLoading = true;
        state.isgrocerybanLoading = true;
        state.isgrocerydeskLoading = true;
        state.isLoading = !state.isLoading;

      }
      state.groceryLoading = false;
    },

    [groceryUpdate.rejected]: (state, action) => {
      state.groceryLoading = true;
    },

    [groceryDel.pending]: (state) => {
      state.delgroceyLoading = true;
    },

    [groceryDel.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.id
        );
        state.groceryicon = "";
        state.grocerybanner = [];
        state.grocerydesktop = "";
        state.grocerythumb = "";
        state.isgrocerybanLoading = true;
        state.isgrocerydeskLoading = true;
        state.isgroceryIconLoading = true;
        state.isgrocerythumbLoading = true;

      }
      state.delgroceyLoading = false;
    },

    [groceryDel.rejected]: (state, action) => {
      state.delgroceyLoading = true;
    },

    [groceryIcon.pending]: (state) => {
      state.isgroceryIconLoading = true;
    },

    [groceryIcon.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.groceryicon = action.payload.icons;
      }
      state.isgroceryIconLoading = false;
    },
    [groceryIcon.rejected]: (state, action) => {
      state.isgroceryIconLoading = true;
    },

    [groceryThumbnail.pending]: (state) => {
      state.isgrocerythumbLoading = true;
    },

    [groceryThumbnail.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerythumb = action.payload.thumbnails;
      }
      state.isgrocerythumbLoading = false;
    },

    [groceryThumbnail.rejected]: (state, action) => {
      state.isgrocerythumbLoading = true;
    },

    [groceryDesktop.pending]: (state) => {
      state.isgrocerydeskLoading = true;
    },

    [groceryDesktop.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerydesktop = action.payload.desktopIcon;
      }
      state.isgrocerydeskLoading = false;
    },

    [groceryDesktop.rejected]: (state, action) => {
      state.isgrocerydeskLoading = true;
    },

    [groceryBanner.pending]: (state) => {
      state.isgrocerybanLoading = true;
    },
    [groceryBanner.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerybanner = [...state.grocerybanner, action.payload.banners];
      }

      state.isgrocerybanLoading = false;
    },
    [groceryBanner.rejected]: (state) => {
      state.isgrocerybanLoading = true;
    },

    [groceryBannerNew.pending]: (state) => {
      state.isgrocerybanLoading = true;
    },
    [groceryBannerNew.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerybanner = [...state.grocerybanner, action.payload.banners];
        state.grocerydesktop = action.payload.desktopIcon;
        state.grocerythumb = action.payload.thumbnails;
        state.groceryicon = action.payload.icons;
      }

      state.isgrocerybanLoading = false;
      state.isgroceryIconLoading = false;
      state.isgrocerydeskLoading = false;
      state.isgrocerythumbLoading = false;
    },
    [groceryBannerNew.rejected]: (state) => {
      state.isgrocerybanLoading = true;
    },

    [grocerypacksizeUpdate.pending]: (state) => {
      state.grocerypacksizeLoading = true;
    },

    [grocerypacksizeUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocerypacksize) =>
            grocerypacksize._id !== action.payload.grocerypacksize._id
        );
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        state.groceryicon = "";
        state.grocerybanner = [];
        state.grocerydesktop = "";
        state.grocerythumb = "";
        state.isgroceryIconLoading = true;
        state.isgrocerythumbLoading = true;
        state.isgrocerybanLoading = true;
        state.isgrocerydeskLoading = true;
      }
      state.grocerypacksizeLoading = false;
    },

    [grocerypacksizeUpdate.rejected]: (state, action) => {
      state.grocerypacksizeLoading = true;
    },
  },
});
export const {
  updateGroceryIcon,
  updateGroceryThumbnail,
  updateGroceryBanner,
  updateGroceryDesktop,
  resetImage,
} = GrocerySlice.actions;
export default GrocerySlice.reducer;
