import "./App.css";

import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import AddBrand from "./pages/productSetting/brand/AddBrand";
import AddCategory from "./pages/productSetting/category/AddCategory";
import AddSuperCategory from "./pages/productSetting/superCategory/AddSuperCategory";
import Loginpage from "./pages/Loginpage";
import AddSubCategory from "./pages/productSetting/SubCategory/AddSubCategory";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getGrocery } from "./redux/grocery/groceySlice";
import AddGrocery from "./pages/productSetting/grocery/AddGrocery";
import ListSuperCategory from "./pages/productSetting/superCategory/ListSuperCategory";
import ListSubCategory from "./pages/productSetting/SubCategory/ListSubCategory";
import ListCategory from "./pages/productSetting/category/ListCategory";
import ListBrand from "./pages/productSetting/brand/ListBrand";
import UpdateBrand from "./pages/productSetting/brand/UpdateBrand";
import UpdateCategory from "./pages/productSetting/category/UpdateCategory";
import ListGrocery from "./pages/productSetting/grocery/ListGrocery";
import UpdateSubCategory from "./pages/productSetting/SubCategory/UpdateSubCategory";
import UpdateSuperCategory from "./pages/productSetting/superCategory/UpdateSuperCategory";
import GroceryPackSize from "./pages/productSetting/grocerypacksize/GroceryPackSize";
import ListGroceryPackSize from "./pages/productSetting/grocerypacksize/ListGroceryPackSize";
import UpdateGroceryPackSize from "./pages/productSetting/grocerypacksize/UpdateGroceryPackSize";
import GroceryBulkUpload from "./pages/productSetting/grocery/GroceryBulkUpload";
import GroceryBulkUploadList from "./pages/productSetting/grocery/GroceryBulkUploadList";
import { getGroceryBulk } from "./redux/GroceryBulkUpload/GroceryBulkUploadSlice";
import ForgetPassword from "./pages/ForgetPassword";
import AddNewGrocery from "./pages/productSetting/grocery/AddNewGrocery";
import UpdateGroceryNew from "./pages/productSetting/grocery/UpdateGroceryNew";
import AdminRegister from "./pages/AdminRegister";

import PasswordChange from "./pages/PasswordChange";
import { getAmin } from "./redux/admin/AdminSlice";
import AddProduct from "./pages/productSetting/product/AddProduct";
import ListProduct from "./pages/productSetting/product/ListProduct";
import ProductBulkUploade from "./pages/productSetting/product/ProductBulkUploade";
import OptionsBulkUploade from "./pages/productSetting/product/OptionsBulkUploade";
import BulkCategoryUploade from "./pages/productSetting/category/BulkCategoryUploade";
import BulkSubCategoryUploade from "./pages/productSetting/SubCategory/BulkSubCategoryUploade";
import BulkBrandUploade from "./pages/productSetting/brand/BulkBrandUploade";
import AddSlider from "./pages/productSetting/slider/AddSlider";
import { getSlider } from "./redux/slider/sliderSlice";
import BookedOrder from "./pages/orders/BookedOrder";
import OrderDetails from "./pages/orders/OrderDetails";
import AssignDeliveryPersion from "./pages/orders/AssignDeliveryPersion";
import CancelledOrder from "./pages/orders/CancelledOrder";
import DeliveredOrder from "./pages/orders/DeliveredOrder";
import DispatchOrder from "./pages/orders/DispatchOrder";
import OutForDeliveryOrder from "./pages/orders/OutForDeliveryOrder";
import ProcessingOrder from "./pages/orders/ProcessingOrder";
import AddShop from "./pages/productSetting/shop/AddShop";
import ListShop from "./pages/productSetting/shop/ListShop";
import { getShop } from "./redux/shop/ShopSlice";
import UpdateShop from "./pages/productSetting/shop/UpdateShop";
import SubscriptionsList from "./pages/Subscriptions/SubscriptionsList";
import SubscriptionOrderReceived from "./pages/Subscriptions/SubscriptionOrderReceived";
import SubscriptionOrderDelivered from "./pages/Subscriptions/SubscriptionOrderDelivered";
import AllSubscription from "./pages/Subscriptions/AllSubscription";

function App() {
  const { isShopAvailable, isShopLoading, allShop } = useSelector(
    (store) => store.shop
  );
  const dispatch = useDispatch();
  useEffect(async () => {
    const initialSetup = async () => {
      try {
        //superCategory
        dispatch(getSuperCategory());
        //Category
        dispatch(getCategory());
        //subCategory
        dispatch(getSubCategory());
        //getBrand
        dispatch(getBrand());
        //getSlider
        dispatch(getSlider());
        if (!isShopAvailable && allShop.length === 0) {
          dispatch(getShop());
        }
      } catch (error) {}
    };
    initialSetup();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isShopLoading) {
        dispatch(getShop());
      }
    }, 15000);
  }, []);

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />

            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />
            <Route
              path="/admin/updatesupercategory/:slugurl"
              element={<UpdateSuperCategory />}
            />
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route
              path="/admin/bulk-category-uploade"
              element={<BulkCategoryUploade />}
            />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/bulk-sub-category-uploade"
              element={<BulkSubCategoryUploade />}
            />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />
            <Route
              path="/admin/updatesubcategory/:slugurl"
              element={<UpdateSubCategory />}
            />

            <Route path="/admin/add-products" element={<AddProduct />} />
            <Route
              path="/admin/products-bulk-uploade"
              element={<ProductBulkUploade />}
            />
            <Route
              path="/admin/options-bulk-uploade"
              element={<OptionsBulkUploade />}
            />
            <Route path="/admin/list-products" element={<ListProduct />} />
            {/* <Route path="/admin/updategrocery/:slugurl" element={<UpdateGrocery />}/> */}
            <Route
              path="/admin/updategrocerynew/:slugurl"
              element={<UpdateGroceryNew />}
            />
            <Route
              path="/admin/addgrocerypacksize"
              element={<GroceryPackSize />}
            />
            <Route
              path="/admin/listgrocerypacksize"
              element={<ListGroceryPackSize />}
            />
            <Route
              path="/admin/updategrocerypacksize"
              element={<UpdateGroceryPackSize />}
            />
            <Route
              path="/admin/grocerybulkupload"
              element={<GroceryBulkUpload />}
            />
            <Route
              path="/admin/grocery-bulkupload-list"
              element={<GroceryBulkUploadList />}
            />

            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route
              path="/admin/brand-bulkupload"
              element={<BulkBrandUploade />}
            />
            <Route path="/admin/listbrand" element={<ListBrand />} />
            <Route
              path="/admin/updatebrand/:slugurl"
              element={<UpdateBrand />}
            />

            <Route path="/admin/add-slider" element={<AddSlider />} />

            <Route path="/admin/register" element={<AdminRegister />} />
            <Route path="/admin/password-change" element={<PasswordChange />} />

            {/* Subscription Orders */}
            <Route
              path="/admin/allSubscription"
              element={<AllSubscription />}
            />
            <Route
              path="/admin/subcriptionList"
              element={<SubscriptionsList />}
            />
            <Route
              path="/admin/subscriptionOrderReceived"
              element={<SubscriptionOrderReceived />}
            />
            <Route
              path="/admin/subscriptionOrderDelivered"
              element={<SubscriptionOrderDelivered />}
            />

            {/* order details */}
            <Route path="/admin/bookedorder" element={<BookedOrder />} />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/admin/assign-delivery/:orderid"
              element={<AssignDeliveryPersion />}
            />
            <Route path="/admin/cancelledorder" element={<CancelledOrder />} />
            <Route path="/admin/deliveredorder" element={<DeliveredOrder />} />
            <Route path="/admin/dispatchorder" element={<DispatchOrder />} />
            <Route
              path="/admin/outfordeliveryorder"
              element={<OutForDeliveryOrder />}
            />
            <Route
              path="/admin/processingorder"
              element={<ProcessingOrder />}
            />
            <Route path="/admin/addshop" element={<AddShop />} />
            <Route path="/admin/listshop" element={<ListShop />} />
            <Route path="/admin/updateshop/:id" element={<UpdateShop />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
