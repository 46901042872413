
import React from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  categoryDelete,
} from "../../../redux/category/categorySlice";

const ListGroceryPackSize = () => {
  const dispatch = useDispatch();

  const { grocerytotal, groceryLoading } = useSelector(
    (store) => store.groceryAll
  );
//   const deleteClick = async (e, id) => {
//     const data = await dispatch(categoryDelete(id));

    // if (data.payload.success) {
    //   alert("Category Deleted");
    //   window.location.reload(false);
    // } else {
    //   alert(data.message);
    //   console.log(data.payload.error);
    // }
//   };
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 200,
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "CATEGORY NAME",
      width: 200,
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      field: "ACTION",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updategrocerypacksize/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
            //   onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {groceryLoading ? (
        <div className="load">
          <ClipLoader color={"#D0021B"} loading={groceryLoading} size={30} />
        </div>
      ) : (
        <Fragment>
          <div className="content-wrapper ">
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={grocerytotal}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
              />
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default ListGroceryPackSize;
