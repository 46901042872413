import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    allShop:localStorage.getItem("allShop")
    ? JSON.parse(localStorage.getItem("allShop"))
    :[],
    shopById: localStorage.getItem("shopById")
        ? JSON.parse(localStorage.getItem("shopById"))
        : [],

    isShopAvailable: localStorage.getItem("allShop") ? true : false,
    isShopLoading: true,

    isShopByIdAvailable: localStorage.getItem("shopById") ? true : false,
    isShopByIdLoading: true,

};

export const shopPost = createAsyncThunk(
    "shop/shopPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/shop/new`;
            const resp = await axios.post(url, formData, config);
            console.log(resp.data);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("Shop Not create");
        }
    }
);

export const getShop = createAsyncThunk(
    "shop/getShop",
    async (thunkAPI) => {
        try {
            const url = `${Baseurl}/api/v1/shop/all`;
            const resp = await axios(url);
            console.log(resp.data.shop);
            return resp.data.shop;
        } catch (error) {
            return thunkAPI.rejectWithValue("404 Not Found");
        }
    }
);

export const shopById = createAsyncThunk(
    "shop/shopById",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/shop/shopby/${formData._id}`;
            const resp = await axios.put(url, formData, config);
            return resp.data.shops;
        } catch (error) {
            return thunkAPI.rejectWithValue("Shop Not create");
        }
    }
);

export const validateshopEmail = createAsyncThunk(
    "shop/validateshopEmail",
    async (email, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/shop/email/${email}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);

export const validateshopMobile = createAsyncThunk(
    "shop/validateshopMobile",
    async (mobile, thunkAPI) => {
        let resp = {
            success: false,
            message: "new email",
        };
        try {
            const url = `${Baseurl}/api/v1/shop/mobile/${mobile}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);


const ShopSlice = createSlice({
    name: "shop",
    initialState,
    extraReducers: {
        [shopPost.pending]: (state) => {
            state.isShopLoading = true;
        },

        [shopPost.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.allShop = [...state.allShop, action.payload.shop];

            }
            state.isShopLoading = false;
        },

        [shopPost.rejected]: (state, action) => {
            state.isShopLoading = true;
        },

        [getShop.pending]: (state) => {
            state.isShopLoading = true;
        },
        [getShop.fulfilled]: (state, action) => {
            state.allShop = action.payload;
            localStorage.setItem("allShop", JSON.stringify(state.allShop));
            state.isShopLoading = false;
            state.isShopAvailable = true;
        },
        [getShop.rejected]: (state, action) => {
            state.isShopLoading = true;
        },

        [shopById.pending]: (state) => {
            state.isShopByIdLoading = true;
        },

        [shopById.fulfilled]: (state, action) => {
            state.shopById = action.payload;
            localStorage.setItem("shopById", JSON.stringify(state.shopById));
            state.isShopByIdAvailable = true;
            state.isShopByIdLoading = false;
        },

        [shopById.rejected]: (state) => {
            state.isShopByIdLoading = true;
        },
    }
})
export const { } = ShopSlice.actions;
export default ShopSlice.reducer;