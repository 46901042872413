import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  categoryDelete,
  categoryUpdate,
} from "../../../redux/category/categorySlice";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";
const ListShop = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { allShop } = useSelector((store) => store.shop);
  console.log(allShop, "allshop");

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);
  const [loadData, setLoadData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const dispatch = useDispatch();


  useEffect(() => {
    const cat = [...allShop].sort((a, b) => (a.name > b.name ? 1 : -1));
    setLoadData(cat);
  }, [allShop]);

  const deleteClick = async (e) => {

  };

  const cancelClick = (e, category) => {
    setCancelModel(true);
    setSelectCategory(category);
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        catid: data._id,
        status: value,
      };
    //   const updateCat = await dispatch(categoryUpdate(formData));
    }
  };


  const columns = [

    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "shopName",
      headerName: "SHOP NAME",
      width: 200,
      headerAlign: "center",
    },
   
    {
      field: "STATUS",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    

    {
      field: "ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-2">
            <Link to={`/admin/updateshop/${params.row._id}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-2">
            <Link to="">
              <Button variant="contained" color="success">
                Approve
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: ".ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              // onClick={(e) => deleteClick(e, cellValues.row._id)}
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      {" "}
      <Fragment>
        <div className="content-wrapper ">
          {/* <div className="card-footer">
            <div className="mb-3">
              <CSVLink data={loadData} onClick={() => {}}>
                <button className="btn btn-success mt-3">export data</button>
              </CSVLink>
            </div>
          </div> */}

          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={loadData}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowHeight={100}
              getRowHeight={() => "auto"}
              rowsPerPageOptions={[10]}
              // checkboxSelection
            />
          </div>

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectCategory !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectCategory.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Category
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListShop;
