import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, } from "../../config/BaseUrl";

const initialState = {
    producttotal: [],
    producticon: "",
    productbanner: [],
    productdesktop: "",
    productthumb: "",
    isproductIconLoading: true,
    isproductthumbLoading: true,
    isproductbanLoading: true,
    isproductdeskLoading: true,
    isLoading: true,
    productpacksizeLoading: true,
    productLoading: true,
};
export const productPost = createAsyncThunk(
    "product/productPost",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/product/new`;
            console.log(url,'url');
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("product Not create");
        }
    }
);
export const UpdateProductOptions = createAsyncThunk(
    "product/UpdateProductOptions",
    async (formData, thunkAPI) => {
        try {
            const config = {
                maxBodyLength: Infinity,
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/product/options/${formData.ProductId}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("product Not create");
        }
    }
);
export const validateProductSlugUrl = createAsyncThunk(
    "product/validateProductSlugUrl",
    async (slugurl, thunkAPI) => {
        let resp = {
            success: false,
            message: "new slugurl",
        };
        try {
            const url = `${Baseurl}/api/v1/product/slugurl/${slugurl}`;
            const resp = await axios.get(url);
            return resp.data;
        } catch (error) {
            return error;
        }
    }
);
export const allProductImagesUploade = createAsyncThunk(
    "product/allProductImagesUploade",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/product/allimagesuploade`;
            const resp = await axios.post(url, formData, config);

            return resp.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue("product Not create");
        }
    }
);
export const productUpdate = createAsyncThunk(
    "product/productUpdate",
    async (formData, thunkAPI) => {
        try {
            const config = {
                maxBodyLength: Infinity,
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/product/${formData.productId}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("product Not create");
        }
    }
);
const ProductSlice = createSlice({
    name: "Product",
    initialState,
    reducers: {
        updateProductIcon(state, action) {
            state.producticon = action.payload;
            state.isproductIconLoading = false;
        },
        updateProductThumbnail(state, action) {
            state.productthumb = action.payload;
            state.isproductthumbLoading = false;
        },
        updateProductBanner(state, action) {
            state.productbanner = action.payload;
            state.isproductbanLoading = false;
        },
        updateProductDesktop(state, action) {
            state.productdesktop = action.payload;
            state.isproductdeskLoading = false;
        },
        resetImage(state) {
            state.producticon = "";
            state.productbanner = [];
            state.productdesktop = "";
            state.productthumb = "";
            state.isproductbanLoading = true;
            state.isproductdeskLoading = true;
            state.isproductIconLoading = true;
            state.isproductthumbLoading = true;
        },
    },
    extraReducers: {
        [productPost.pending]: (state) => {
            state.productLoading = true;
        },
        [productPost.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.producttotal = [...state.producttotal, action.payload.product];
                state.producticon = "";
                state.productbanner = [];
                state.productdesktop = "";
                state.productthumb = "";
                state.isproductbanLoading = true;
                state.isproductdeskLoading = true;
                state.isproductIconLoading = true;
                state.isproductthumbLoading = true;
            }
            state.productLoading = false;
        },
        [productPost.rejected]: (state, action) => {
            state.productLoading = true;
        },
        [UpdateProductOptions.pending]: (state) => {
            state.productLoading = true;
        },
        [UpdateProductOptions.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.producttotal = state.producttotal.filter(
                    (product) => product._id !== action.payload.product._id
                );
                state.producttotal = [...state.producttotal, action.payload.product];
                state.productbanner = "";
                state.productdesktop = "";
                state.productthumb = "";
                state.producticon = "";
                state.isproductbanLoading = true;
                state.isproductdeskLoading = true;
                state.isproductIconLoading = true;
                state.isproductthumbLoading = true;
                state.isLoading = !state.isLoading;
            }
            state.productLoading = false;
        },
        [allProductImagesUploade.pending]: (state) => {
            state.isproductbanLoading = true;
        },
        [allProductImagesUploade.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.productbanner = [...state.productbanner, action.payload.banners];
                state.productdesktop = action.payload.desktopIcon;
                state.productthumb = action.payload.thumbnails;
                state.producticon = action.payload.icons;
            }
            state.isproductbanLoading = false;
            state.isproductdeskLoading = false;
            state.isproductIconLoading = false;
            state.isproductthumbLoading = false;
        },
        [allProductImagesUploade.rejected]: (state) => {
            state.isproductbanLoading = true;
        },
        [productUpdate.pending]: (state) => {
            state.productLoading = true;
        },
        [productUpdate.fulfilled]: (state, action) => {
            if (action.payload.success) {
                state.producttotal = state.producttotal.filter(
                    (product) => product._id !== action.payload.product._id
                );
                state.producttotal = [...state.producttotal, action.payload.product];
                state.productbanner = "";
                state.productdesktop = "";
                state.productthumb = "";
                state.producticon = "";
                state.isproductbanLoading = true;
                state.isproductdeskLoading = true;
                state.isproductIconLoading = true;
                state.isproductthumbLoading = true;
                state.isLoading = !state.isLoading;
            }
            state.productLoading = false;
        },
        [productUpdate.rejected]: (state, action) => {
            state.productLoading = true;
        },
    }
});

export const {
    updateProductIcon,
    updateProductThumbnail,
    updateProductBanner,
    updateProductDesktop,
    resetImage,
} = ProductSlice.actions;
export default ProductSlice.reducer;