import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  admintotal:  [],
  adminLoading: true,
};

export const validateadminEmail = createAsyncThunk(
  "admin/validateadminEmail",
  async (email, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/admin/email/${email}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const validateadminMobile = createAsyncThunk(
  "admin/validateadminMobile",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/admin/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const adminPost = createAsyncThunk(
  "admin/adminPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/register`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("agent Not create");
    }
  }
);

export const getAmin = createAsyncThunk("amin/getAmin", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/admin/all`;
    const resp = await axios(url);
    return resp.data.admins;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const adminUpdates = createAsyncThunk(
  "admin/adminUpdates",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/admin/adminupdate/${formData.adminId}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [getAmin.pending]: (state) => {
      state.adminLoading = true;
    },
    [getAmin.fulfilled]: (state, action) => {
      state.admintotal = action.payload;

      state.adminLoading = false;
    },
    [getAmin.rejected]: (state, action) => {
      state.adminLoading = true;
    },

    [adminPost.pending]: (state) => {
      state.adminLoading = true;
    },

    [adminPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.admintotal = [...state.admintotal, action.payload.admin];
      }
      state.adminLoading = false;
    },

    [adminPost.rejected]: (state, action) => {
      state.adminLoading = true;
    },

    [adminUpdates.pending]: (state) => {
      state.adminLoading = true;
    },

    [adminUpdates.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.admintotal = state.admintotal.filter(
          (admin) => admin._id !== action.payload.adminss._id
        );
        state.admintotal = [...state.admintotal, action.payload.adminss];

      }

      state.adminLoading = false;
    },

    [adminUpdates.rejected]: (state, action) => {
      state.adminLoading = true;
    },
  },
});

export const {} = adminSlice.actions;
export default adminSlice.reducer;
