import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { brandPost } from "../../../redux/brand/brandSlice";

const BulkBrandUploade = () => {
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    var date = new Date();
    date.setDate(date.getDate() + 7);
    for (let i in data) {
      let myrow = data[i];
      numbers = i;

      let slugurl = myrow.Brand.trim()
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[.*+&?^ $@#%^!/'{}()|[\]\\]/g, "-")
        .replace("---", "-")
        .replace("----", "-")
        .replace("--", "-");

      const formData = {
        name:
          myrow.Brand.charAt(0).toUpperCase() +
          myrow.Brand.slice(1).toLowerCase(),
        slugUrl: slugurl,

        thumbnail: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,
        icon: `https://mystore.ewshopping.com/admin/subcategory/Mobile/${myrow.MobileImg}`,
        desktopicon: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,
        banner: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,

        BrandId: myrow.BrandId,
        status: myrow.Show,
      };
      const uploaddata = await dispatch(brandPost(formData));

      if (uploaddata.payload.success) {
        setUploadedProductName(
          `${Number(numbers) + 1}) ${myrow.Brand}  upload successfull`
        );
      } else {
        console.log(errordata.length, "data.length");
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Brand Bulk Upload</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Upload Excel File *
                  </Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Brand Bulk Upload
                  </button>
                </Col>
              </Row>

              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BulkBrandUploade;
