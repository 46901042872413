import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  groceryIcon,
  groceryThumbnail,
  groceryBanner,
  groceryDesktop,
  updateGroceryIcon,
  updateGroceryThumbnail,
  updateGroceryBanner,
  updateGroceryDesktop,
  groceryUpdate
} from "../../../redux/grocery/groceySlice";

const UpdateGroceryPackSize = () => {
  const { grocerycategory } = useSelector((store) => store.categoryAll);
  const { grocerytotal } = useSelector((store) => store.groceryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const { brandtotal } = useSelector((store) => store.brandAll);

  const {
    groceryicon,
    grocerythumb,
    grocerybanner,
    grocerydesktop,
    isgroceryIconLoading,
    isgrocerythumbLoading,
    isgrocerybanLoading,
    isgrocerydeskLoading,
  } = useSelector((store) => store.groceryAll);

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);

  const [productId, setProductId] = useState("");
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [productPacksize, setProductPackSize] = useState([]);

  const [packsize, setPacksize] = useState("");
  const [costprice, setCostprice] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [sellingprice, setSellingprice] = useState("");
  const [gstsellprice, setGstsellprice] = useState("");
  const [mrp, setMrp] = useState("");
  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [packsizeoutofstock, setPacksizeutofstock] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [grocerypacksizes, setGrocerypacksizes] = useState([]);
  const [groceryoptionId, setGroceryoptioniD] = useState("");
  const [groceryoption, setGroceryoption] = useState("");

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const selectBrand = (evt) => {
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    const brandbycat = brandtotal.filter(
      (brand) => brand.categoryId === categoryid
    );
    setCatbrand(brandbycat);
  }, [categoryid]);

  const selectProduct = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setProductId(value);
    const getsingleproduct = grocerytotal.find((prod) => prod._id === value);
    setProduct(getsingleproduct);

    setGrocerypacksizes(getsingleproduct.options);
  };
  const selectoptionProduct = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setGroceryoptioniD(value);
    const getsingleoption = grocerypacksizes.find((option) => option._id === value);
    setGroceryoption(getsingleoption);
  };

  useEffect(() => {
    setCostprice(groceryoption.costPrice)
    setPacksize(groceryoption.optionName)
    setGstcost(groceryoption.gstCost)
    setGstsellprice(groceryoption.gstSellPrice)
    setSellingprice(groceryoption.sellingPrice)
    setMrp(groceryoption.mrp)
    setHotproducts(groceryoption.hot)
    setTrending(groceryoption.trending)
    setPacksizeutofstock(groceryoption.outOfStock)
    setOffers(groceryoption.offers)
    dispatch(updateGroceryIcon(groceryoption.icon))
    dispatch(updateGroceryThumbnail(groceryoption.thumbnail))
    dispatch(updateGroceryDesktop(groceryoption.desktopicon))
    dispatch(updateGroceryBanner(groceryoption.productSlider))
  }, [groceryoptionId]);

  useEffect(() => {
    const productbycat = grocerytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setProducts(productbycat);
  }, [categoryid]);

  const discount = Math.round(((mrp - costprice) * 100) / mrp);

  const dispatch = useDispatch();

  const handlesubmit = async (e) => {
    e.preventDefault();
    const option = {
      optionName: packsize,
      thumbnail: grocerythumb,
      icon: groceryicon,
      desktopicon: grocerydesktop,
      productSlider: grocerybanner,
      costPrice: costprice,
      gstCost: gstcost,
      sellingPrice: sellingprice,
      gstSellPrice: gstsellprice,
      mrp: mrp,
      hot: hotproducts,
      trending: trending,
      outOfStock: packsizeoutofstock,
      offers: offers,
      discounts: discount,
    };
   

    let currentoption= product.options.filter((option) => option._id !== groceryoptionId);
    let Updatedproductoptions = [...currentoption, option];
    const formData = {
      options: Updatedproductoptions,
      groceryid: productId,
    };
    const data = await dispatch(groceryUpdate(formData));
    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Grocery pack size updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  const deleteClick = async (e) =>{
    e.preventDefault();

    let Updatedproductoptions= product.options.filter((option) => option._id !== groceryoptionId);



    const formData = {
      options: Updatedproductoptions,
      groceryid: productId,
    };
    const data = await dispatch(groceryUpdate(formData));
    setProductId("");
    // document.getElementById("myForm").reset();
  }

  const iconChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryIcon({ icon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryThumbnail({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const bannerChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryBanner({ banner: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryDesktop({ desktopicon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };



  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit} id="myForm">
              <h4 className="p-4">Update SuperMart Pack Size</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update SuperMart Pack Size</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={grocerycategory._id}
                        name={grocerycategory.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {grocerycategory.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        name={catbrand.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {catbrand.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectProduct(e);
                        }}
                        value={products._id}
                        name={products.name}
                      >
                        <option value="0">{"Select Product"}</option>
                        {products.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        select Product Option *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                         onChange={(e) => {
                          selectoptionProduct(e);
                        }}
                        value={grocerypacksizes._id}
                        name={grocerypacksizes.name}
                     
                      >
                        <option value="0">{"Select Product Pack Size"}</option>
                        {grocerypacksizes && grocerypacksizes.map((Grocerypacksize,index) => (
                          <option
                            key={index}
                            value={Grocerypacksize._id}
                            name={Grocerypacksize.optionName}
                            required
                          >
                            {Grocerypacksize.optionName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Purches Price *
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter slugUrl....."
                        value={costprice}
                        onChange={(e) => setCostprice(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Icon</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={iconChange}
                      />
                      <p>* The image width and height should be 45px * 45px</p>
                      <div>
                        {isgroceryIconLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={groceryicon}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Thumbnail</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={thumbnailChange}
                      />
                      <p>
                        * The image width and height should be 150px * 150px
                      </p>
                      <div>
                        {isgrocerythumbLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={grocerythumb}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Product Slider</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={bannerChange}
                        multiple
                      />
                      <p>
                        * The image width and height should be 600px * 600px
                      </p>
                      <div>
                        {isgrocerybanLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {grocerybanner && grocerybanner.map((data, index) => (
                              <img
                                src={data}
                                key={index}
                                width={150}
                                height={150}
                                alt="img"
                                className="mx-2"
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Desktop Icon</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={desktopChange}
                      />
                      <p>* The image width and height should be 75px * 75px</p>
                      <div>
                        {isgrocerydeskLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={grocerydesktop}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        GST Cost *
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter name....."
                        value={gstcost}
                        onChange={(e) => setGstcost(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Selling Price *
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter slugUrl....."
                        value={sellingprice}
                        onChange={(e) => setSellingprice(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        GST Sell Price *
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter name....."
                        value={gstsellprice}
                        onChange={(e) => setGstsellprice(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">MRP *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter mrp....."
                        value={mrp}
                        onChange={(e) => setMrp(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="hotproducts"
                        checked={hotproducts === true}
                        onChange={(e) => {
                          setHotproducts(e.target.checked);
                        }}
                      />
                      <Form.Label>Hot Products *</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="trending"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending *</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="packsizeoutofstock"
                        checked={packsizeoutofstock === true}
                        onChange={(e) => {
                          setPacksizeutofstock(e.target.checked);
                        }}
                      />
                      <Form.Label>Pack Size Out Of Stock *</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offers*</Form.Label>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center ">
                  <button className="btn btn-primary me-2 " type="submit">
                    Update Grocery Pack Size
                  </button>
                  <button className="btn btn-success me-2" type="reset">
                    Clear All
                  </button>
                  <button className="btn btn-danger me-2" onClick={(e) =>deleteClick(e)} >
                    Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateGroceryPackSize;
