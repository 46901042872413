import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { subCategoryPost } from "../../../redux/subCategory/subCategorySlice";

const BulkSubCategoryUploade = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);

  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    var date = new Date();
    date.setDate(date.getDate() + 7);
    for (let i in data) {
      let myrow = data[i];
      numbers = i;
      const formData = {
        name: myrow.subCatName,
        slugUrl: myrow.Url,
        category: category,
        categoryId: categoryid,

        subcatId: myrow.subcatId,
        catId: myrow.catId,

        Footer: myrow.Footer,

        metaTitle: myrow.Title,
        metaKeyword: myrow.Keywords,
        metaDesc: myrow.Description,

        thumbnail: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,
        icon: `https://mystore.ewshopping.com/admin/subcategory/Mobile/${myrow.mobImg}`,
        desktopicon: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,
        banner: `https://mystore.ewshopping.com/admin/subcategory/Desktop/${myrow.DeskImg}`,

        status: myrow.Show,
      };
      const uploaddata = await dispatch(subCategoryPost(formData));

      if (uploaddata.payload.success) {
        setUploadedProductName(
          `${Number(numbers) + 1}) ${myrow.subCatName}  upload successfull`
        );
      } else {
        console.log(errordata.length, "data.length");
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Sub-Category Bulk Upload</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2 w-50">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Category Name
                  </Form.Label>

                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectCategory(e);
                    }}
                    value={categorytotal._id}
                    name={categorytotal.name}
                  >
                    <option value="0">{"Select Category"}</option>
                    {categorytotal.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name} {data.catId}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Upload Excel File *
                  </Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Sub-Category Bulk Upload
                  </button>
                </Col>
              </Row>

              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BulkSubCategoryUploade;
