import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  groceryimageresorces: [],
  latestImageresorcesIcon: [],
  latestImageresorcesThumnail: [],
  latestImageresorcesBanner: [],
  latestImageresorcesDesktop: [],
  groceryicon: [],
  grocerybanner: [],
  grocerydesktop: [],
  grocerythumb: [],
  isgroceryIconLoading: true,
  isgrocerythumbLoading: true,
  isgrocerybanLoading: true,
  isgrocerydeskLoading: true,
  isLoading: true,
  groceryLoading: true,
  grocerypacksizeLoading: true,
  delgroceyLoading: true,
};

export const getGroceryBulk = createAsyncThunk(
  "grocery/getGroceryBulk",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/grocerybulkimages/all`;
      const resp = await axios(url);
      return resp.data.imageResource;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const groceryBulkBanner = createAsyncThunk(
  "grocery/groceryBulkBanner",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/banner`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryBulkIcon = createAsyncThunk(
  "grocery/groceryBulkIcon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/icon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Icon Not create");
    }
  }
);

export const groceryBulkThumbnail = createAsyncThunk(
  "grocery/groceryBulkThumbnail",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };

      const url = `${Baseurl}/api/v1/grocery/thumbnail`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Thumbnail Not create");
    }
  }
);

export const groceryBulkDesktop = createAsyncThunk(
  "grocery/groceryBulkDesktop",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/desktopicon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Icon Not create");
    }
  }
);

export const groceryBulkimag = createAsyncThunk(
  "grocery/groceryBulkimag",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/grocery/bannernew`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery iamges Not create");
    }
  }
);

const GrocerBulkSlice = createSlice({
  name: "groceryBulk",
  initialState,
  reducers: {
    latesuplodeIcons(state, action) {
      state.latestImageresorcesIcon = action.payload;
    },
    latesuplodeThumbnails(state, action) {
      state.latestImageresorcesThumnail = action.payload;
    },
    latesuplodeBanner(state, action) {
      state.latestImageresorcesBanner = action.payload;
    },
    latesuplodedesktop(state, action) {
      state.latestImageresorcesDesktop = action.payload;
    },
  },
  extraReducers: {
    [getGroceryBulk.pending]: (state) => {
      state.isLoading = true;
    },
    [getGroceryBulk.fulfilled]: (state, action) => {
      state.groceryimageresorces = action.payload;

      state.isLoading = false;
      state.groceryLoading = false;
    },
    [getGroceryBulk.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [groceryBulkBanner.pending]: (state) => {
      state.isgrocerybanLoading = true;
    },
    [groceryBulkBanner.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerybanner = [...state.grocerybanner, action.payload.banners];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
      }

      state.isgrocerybanLoading = false;
    },
    [groceryBulkBanner.rejected]: (state) => {
      state.isgrocerybanLoading = true;
    },
    [groceryBulkimag.pending]: (state) => {
      state.isgrocerybanLoading = true;
    },
    [groceryBulkimag.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerybanner = [...state.grocerybanner, action.payload.banners];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
        state.groceryicon = [...state.groceryicon, action.payload.icons];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
        state.grocerythumb = [...state.grocerythumb, action.payload.thumbnails];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
        state.grocerydesktop = [
          ...state.grocerydesktop,
          action.payload.desktopIcon,
          (state.groceryimageresorces = [
            ...state.groceryimageresorces,
            action.payload.imageResource,
          ]),
        ];
      }

      state.isgrocerybanLoading = false;
      state.isgroceryIconLoading = false;
      state.isgrocerythumbLoading = false;
      state.isgrocerydeskLoading = false;
    },
    [groceryBulkimag.rejected]: (state) => {
      state.isgrocerybanLoading = true;
    },

    [groceryBulkIcon.pending]: (state) => {
      state.isgroceryIconLoading = true;
    },

    [groceryBulkIcon.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.groceryicon = [...state.groceryicon, action.payload.icons];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
      }
      state.isgroceryIconLoading = false;
    },
    [groceryBulkIcon.rejected]: (state, action) => {
      state.isgroceryIconLoading = true;
    },

    [groceryBulkThumbnail.pending]: (state) => {
      state.isgrocerythumbLoading = true;
    },

    [groceryBulkThumbnail.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerythumb = [...state.grocerythumb, action.payload.thumbnails];
        state.groceryimageresorces = [
          ...state.groceryimageresorces,
          action.payload.imageResource,
        ];
      }
      state.isgrocerythumbLoading = false;
    },

    [groceryBulkThumbnail.rejected]: (state, action) => {
      state.isgrocerythumbLoading = true;
    },

    [groceryBulkDesktop.pending]: (state) => {
      state.isgrocerydeskLoading = true;
    },

    [groceryBulkDesktop.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerydesktop = [
          ...state.grocerydesktop,
          action.payload.desktopIcon,
          (state.groceryimageresorces = [
            ...state.groceryimageresorces,
            action.payload.imageResource,
          ]),
        ];
      }
      state.isgrocerydeskLoading = false;
    },

    [groceryBulkDesktop.rejected]: (state, action) => {
      state.isgrocerydeskLoading = true;
    },
  },
});
export const {
  latesuplodeIcons,
  latesuplodeThumbnails,
  latesuplodeBanner,
  latesuplodedesktop,
} = GrocerBulkSlice.actions;
export default GrocerBulkSlice.reducer;
