import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const GroceryBulkUploadList = () => {
  const { groceryimageresorces, isLoading } = useSelector(
    (store) => store.groceryBulk
  );
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filterproducts, setFilterProducts] = useState([]);
  const [imagetyp, setImagetyp] = useState("Select");

  useEffect(() => {
    const result = groceryimageresorces.filter((product) => {
      return product.Familyname.match(search);
    });
    setFilterProducts(result);
  }, [search]);

  useEffect(() => {
    const reverselist = groceryimageresorces.slice().reverse();

    if (imagetyp === "Select") {
      if (search === "") {
        setFilterProducts(reverselist);
      } else {
        const result = reverselist.filter((image) => {
          return image.Familyname.toLowerCase().match(search.toLowerCase());
        });
        setFilterProducts(result);
      }
    } else {
      if (search === "") {
        const result = reverselist.filter(
          (image) => image.imagetype === imagetyp
        );
        setFilterProducts(result);
      } else {
        const result = reverselist.filter((image) => {
          return (
            image.imagetype === imagetyp &&
            image.Familyname.toLowerCase().match(search.toLowerCase())
          );
        });
        setFilterProducts(result);
      }
    }
  }, [search, isLoading]);

  const selectimagetype = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    const imagetyp = value;
    setImagetyp(imagetyp);

    if (imagetyp === "Select") {
      if (search === "") {
        setFilterProducts(groceryimageresorces);
      } else {
        const result = groceryimageresorces.filter((product) => {
          return product.Familyname.toLowerCase().match(search.toLowerCase());
        });
        setFilterProducts(result);
      }
    } else {
      if (search === "") {
        const result = groceryimageresorces.filter(
          (product) => product.imagetype === imagetyp
        );
        setFilterProducts(result);
      } else {
        const result = groceryimageresorces.filter((product) => {
          return (
            product.imagetype === imagetyp &&
            product.Familyname.toLowerCase().match(search.toLowerCase())
          );
        });

        setFilterProducts(result);
      }
    }
  };

  const imagetype = [
    { id: 1, type: "thumbnail" },
    { id: 2, type: "icons" },
    { id: 3, type: "product slider" },
    { id: 4, type: "desktopIcon" },
  ];

  const columns = [
    {
      field: "Familyname",
      headerName: " NAME",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: " IMAGE URL",
      width: 770,
      headerAlign: "center",
      align: "start",
    },

    {
      field: "imagetype",
      headerName: " IMAGE TYPE",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: "IMAGE",
      width: 180,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.image}
              width={60}
              alt="desktopBanner"
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card m-4">
            <div className="card-footer">
              <h5> SuperMart Bulk Products List</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2 w-50">
                <Col md>
                  <Form.Control
                    type="text"
                    placeholder="Search grocery products"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
                <Col md>
                  {/* <Form.Label htmlFor="disabledTextInput">Category</Form.Label> */}
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectimagetype(e);
                    }}
                    value={imagetype.name}
                    name={imagetype.name}
                  >
                    <option value="Select">{"Select Image Type"}</option>
                    {imagetype.map((data, index) => (
                      <option key={data.id} value={data.type} name={data.type}>
                        {data.type}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
          </div>
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={filterproducts}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default GroceryBulkUploadList;
