import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPost,
  validateadminEmail,
  validateadminMobile,
} from "../redux/admin/AdminSlice";

const AdminRegister = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [errormassageEmail, setErrormassageEmail] = useState("");
  const [uniqueemail, setUniqueEmail] = useState("");
  const [errormassage, setErrormassage] = useState("");
  const [errormassageone, setErrormassageone] = useState("");
  const [comformphoneNo, setComformphoneNo] = useState("");
  const [errormsgClr, setErrormsgclr] = useState("red");

  const dispatch = useDispatch();

  const verifyemail = async (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrormsgclr("green");
    setErrormassageEmail("");
    if (value != "") {
      let responce = await dispatch(validateadminEmail(value));

      if (responce.payload.success) {
        setErrormassageEmail("Email already exist");
      } else {
        setUniqueEmail(value);
        setErrormassageEmail("");
      }
    }
  };
  
  const verifyPhoneNumber = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobile(value);
    setErrormassage("");
    if (value.length === 10) {
      let responce = await dispatch(validateadminMobile(value));

      if (responce.payload.success) {
        setErrormassage("Number already exist");
      } else {
        setComformphoneNo(value);
        setErrormassage("");
      }
    } else{
        setErrormassage("Please enter valid number");
    }
  };

  const confirmpasswordChange = (e) => {
    const value = e.target.value;
    setConfirmpassword(value);
    setErrormassageone("");

    if (value != password) {
      setErrormsgclr("red");
      setErrormassageone("password not matched");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (uniqueemail != "" && comformphoneNo != "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        email: uniqueemail,
        password: confirmpassword,
        mobile: comformphoneNo,
      };
      const data = await dispatch(adminPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Admin Added");
          setName("");
          setEmail("");
          setMobile("");
          setConfirmpassword("");
          setPassword("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter currect data");
    }
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Admin Registration </h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Admin Registration </h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Full Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Email</Form.Label>

                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onChange={(e) => verifyemail(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errormassageEmail}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=" Confirm Password"
                        required
                        value={confirmpassword}
                        onChange={(e) => confirmpasswordChange(e)}
                      />
                      <p
                        style={{ color: errormsgClr, fontWeight: 500 }}
                        className="mt-2"
                      >
                        {errormassageone}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Mobile
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        required
                        minLength={10}
                        maxLength={10}
                        pattern="[6789][0-9]{9}"
                        onChange={(e) => verifyPhoneNumber(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errormassage}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center ">
                  {!errormassage.length > 0 &&
                  !errormassageEmail.length > 0 &&
                  !errormassageone.length > 0 ? (
                    <button className="btn btn-primary me-2" type="submit">
                      Registration
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Registration
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AdminRegister;
