import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

export const adminRegister = createAsyncThunk(
  "admin/adminRegister",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not registered",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/login`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changeAuthentication = createAsyncThunk(
  "admin/changeAuthentication",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const otp = formdetails.OTP;

      const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=BMAXMS&channel=2&DCS=0&flashsms=0&number=${mob}&text=Your%20OTP%20for%20logging%20in%20to%20Salesbees%20account%20is%20${otp}.%20Do%20not%20share%20with%20anyone.%20%E2%80%93%20BMAX%20Marketing%20Solutions.&route=1&EntityId=1201166728788735437&dlttemplateid=1207166791590878810`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) { }

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);

export const orderCancelMassage = createAsyncThunk(
  "admin/orderCancelMassage",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const name = formdetails.clientname;
      const orderId = formdetails.orderId;

      const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=SLBEEZ&channel=2&DCS=0&flashsms=0&number=${mob}&text=Hi ${name}, thank you for shopping at Salesbeez. Your order no ${orderId} has been canceled. Salesbeez.&route=1&EntityId=1201166728788735437&dlttemplateid=1207169262502775070`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) { }

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);
export const dispachedOrderMassage = createAsyncThunk(
  "admin/dispachedOrderMassage",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const logisticspartnerName = formdetails.logisticspartnerName;
      const logisticsawbnumber = formdetails.logisticsawbnumber;
      const orderId = formdetails.orderId;

      const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=SLBEEZ&channel=2&DCS=0&flashsms=0&number=${mob}&text=Your order no ${orderId} has been dispatched. Dispatched Through ${logisticspartnerName} and Your Consignment Number is ${logisticsawbnumber} - SalesBeez.&route=1&EntityId=1201166728788735437&dlttemplateid=1207169262629630557`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) { }

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);
export const orderDeliveredMassage = createAsyncThunk(
  "admin/orderDeliveredMassage",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const orderId = formdetails.orderId;

      const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=SLBEEZ&channel=2&DCS=0&flashsms=0&number=${mob}&text=Your%20order%20no%20${orderId}%20has%20been%20delivered.%20Please%20give%20your%20feedback%20%26%20Rate%20Us%20on%20Play%20Store%20-%20SalesBeez.&route=1&EntityId=1201166728788735437&dlttemplateid=1207169262641897269`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) { }

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);


export const changeAuthenticationTwo = createAsyncThunk(
  "admin/changeAuthenticationTwo",
  async (formdetails, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const mob = formdetails.number;
      const otp = formdetails.OTP;

      const url = `https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=BMAXMS&channel=2&DCS=0&flashsms=0&number=${mob}&text=Your%20OTP%20for%20logging%20in%20to%20Salesbees%20account%20is%20${otp}.%20Do%20not%20share%20with%20anyone.%20%E2%80%93%20BMAX%20Marketing%20Solutions.&route=1&EntityId=1201166728788735437&dlttemplateid=1207166791590878810`;
      try {
        const res = await fetch(url);
        res
          .json()
          .then((res) => res)
          .catch((err) => console.log(err));
      } catch (error) { }

      resp = await axios.get(url);
      console.log(resp);

      return resp;
    } catch (error) {
      return error;
    }
  }
);

export const adminUpdate = createAsyncThunk(
  "admin/adminUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/adminupdate/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("agent Not create");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: JSON.parse(localStorage.getItem("loginData"))?.isAuth
      ? JSON.parse(localStorage.getItem("loginData")).isAuth
      : false,
    token: JSON.parse(localStorage.getItem("loginData"))?.token
      ? JSON.parse(localStorage.getItem("loginData")).token
      : "",
    name: JSON.parse(localStorage.getItem("loginData"))?.name
      ? JSON.parse(localStorage.getItem("loginData")).name
      : "",
    email: JSON.parse(localStorage.getItem("loginData"))?.email
      ? JSON.parse(localStorage.getItem("loginData")).email
      : "",
    clientid: JSON.parse(localStorage.getItem("loginData"))?.clientid
      ? JSON.parse(localStorage.getItem("loginData")).clientid
      : "",
    loginData: localStorage.getItem("loginData")
      ? JSON.parse(localStorage.getItem("loginData"))
      : "",

    officerName: "",
    officerMobile: "",
    isAuthLoading: true,
  },
  reducers: {
    signin(state, action) {
      state.loginData = action.payload;
      state.officerName = action.payload.officersName;
      state.officerMobile = action.payload.officerMobile;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      state.isAuth = true;
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      // localStorage.clear();
      state.isAuth = false;
    },
  },
  extraReducers: {
    [adminUpdate.pending]: (state) => {
      state.isAuthLoading = true;
    },

    [adminUpdate.fulfilled]: (state, action) => {
      console.log(action.payload);
      if (action.payload.success) {
        state.loginData = action.payload.adminss;
        let adminDetails = state.loginData;
        state.loginData.officersName = adminDetails.officersName;
        state.loginData.officerMobile = adminDetails.officerMobile;

        let logindata = {
          ...adminDetails,
          isAuth: true,
        };
        state.loginData = logindata;
        localStorage.setItem("loginData", JSON.stringify(state.loginData));
      }
      state.isAuthLoading = false;
    },

    [adminUpdate.rejected]: (state, action) => {
      state.isAuthLoading = true;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
