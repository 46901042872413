 

 import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {updateSuperCategory} from "../../../redux/superCategory/superCategorySlice";

const UpdateSuperCategory = () => {

  const { superCat } = useSelector((store) => store.superCategory);
  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [supercatId, setSupercatId] = useState("");

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const supercatUrl = params.slugurl;

    const updatesupercat = superCat.find((supercat) => supercat.slugUrl === supercatUrl);

    setSupercatId(updatesupercat._id);
    setNames(updatesupercat.name);
    setSlugUrl(updatesupercat.slugUrl);
    setMetatitle(updatesupercat.metaTitle);
    setMetakeyword(updatesupercat.metaKeyword);
    setMetadesc(updatesupercat.metaDesc);
  }, []);
  
  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const namechange = (e) => {
    const name = e.target.value;
    setNames(name);

    let slugurl = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^$@#%^!{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

      setSlugUrl(slugurl);

  }
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
      slugUrl:slugUrl,
      metaTitle:metatitle,
      metaKeyword:metakeyword,
      metaDesc:metadesc,
      supercatid: supercatId
    };
    const data = await dispatch(updateSuperCategory(formData))

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("super Category Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  
  const clearClick = () =>{
    setSlugUrl("");
    setNames("");
    setMetatitle("");
    setMetakeyword("");
    setMetadesc("");
    
  }

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Super Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Super Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        
                        placeholder="enter name....."
                        value={names}
                        onChange={namechange}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        slugUrl*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter slugUrl....."
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center ">
                  <button className="btn btn-primary me-2" type="submit">
                  Update Super Category
                  </button>
                  <button className="btn btn-danger" onClick={clearClick} type="reset">
                    Clear All
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSuperCategory;
