import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";

const Dashboard = () => {
  const { categorytotal, categoryLoading } = useSelector(
    (store) => store.categoryAll
  );
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const [products, setProducts] = useState([])
  const [orders, setOrders] = useState([])
  const [subOrders, setSubOrders] = useState([])
  useEffect(() => {
    const fetchProduct = async () => {
      const url = `${Baseurl}/api/v1/product/all`;
      const fetchproducts = await axios.get(url);
      try {
        if (fetchproducts.data.success === true) {
          const prod = fetchproducts.data.products;
          const reverlist = [...prod].sort((a, b) =>
            a.ItemName > b.ItemName ? 1 : -1
          );
          setProducts(reverlist);
        }
      } catch (error) { }
    };
    fetchProduct();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/order/orderrecieved`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        if (orderTotal.length > 0) {
          setOrders(orderTotal.reverse());
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/subscription-order/orderreceived`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        setSubOrders(orderTotal.reverse());
      }
    };
    fetchData();
  }, []);


  return (
    <>
      {isAuth ? (
        <Fragment>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a>Home</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <section className="content">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
                <div
                  className="small-box "
                  style={{ padding: "10px", width: '180px', backgroundColor: '#8533ff', color: '#fff' }}
                >
                  <div className="inner">
                    <h4>{categorytotal.length}</h4>
                    <p style={{ height: "40px", marginTop: "10px" }}>
                      Total Categories
                    </p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag " />
                  </div>
                  <a className="small-box-footer">
                    <Link
                      to="/admin/listcategory"
                      style={{ color: "#fff" }}
                    >
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </a>
                </div>
                <div
                  className="small-box"
                  style={{ padding: "10px", width: '180px', backgroundColor: '#a64dff', color: '#fff' }}
                >
                  <div className="inner">
                    <h4>{subcategorytotal?.length > 0 ? subcategorytotal?.length : 0}</h4>
                    <p style={{ height: "40px", marginTop: "10px" }}>
                      Total Subcategories
                    </p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag " />
                  </div>
                  <a className="small-box-footer">
                    <Link
                      to="/admin/listsubcategory"
                      style={{ color: "#fff" }}
                    >
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </a>
                </div>
                <div
                  className="small-box"
                  style={{ padding: "10px", width: '180px', backgroundColor: '#ff4dff', color: '#fff' }}
                >
                  <div className="inner">
                    <h4>{products.length}</h4>
                    <p style={{ height: "40px", marginTop: "10px" }}>
                      Total Products
                    </p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag " />
                  </div>
                  <a className="small-box-footer">
                    <Link
                      to="/admin/list-products"
                      style={{ color: "#fff" }}
                    >
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </a>
                </div>
                <div
                  className="small-box"
                  style={{ padding: "10px", width: '180px', backgroundColor: '#d147a3', color: '#fff' }}
                >
                  <div className="inner">
                    <h4>{orders.length}</h4>
                    <p style={{ height: "40px", marginTop: "10px" }}>
                      Booked Orders
                    </p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag " />
                  </div>
                  <a className="small-box-footer">
                    <Link
                      to="/admin/bookedorder"
                      style={{ color: "#fff" }}
                    >
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </a>
                </div>
                <div
                  className="small-box"
                  style={{ padding: "10px", width: '180px', backgroundColor: '#ff3399', color: '#fff' }}
                >
                  <div className="inner">
                    <h4>{subOrders.length}</h4>
                    <p style={{ height: "40px", marginTop: "10px" }}>
                      Subscription Orders
                    </p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag " />
                  </div>
                  <a className="small-box-footer">
                    <Link
                      to="/admin/subscriptionOrderReceived"
                      style={{ color: "#fff" }}
                    >
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Dashboard;
