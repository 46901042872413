import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  brandAllimgageUploade,
  updateBrandIcon,
  updateBrandThumbnail,
  updateBrandBanner,
  updateBrandDesktop,
  brandUpdate,
} from "../../../redux/brand/brandSlice";

const UpdateBrand = () => {
  const {
    brandicon,
    brandbanner,
    branddesktop,
    brandthumb,
    isbrandbanLoading,
    isbranddeskLoading,
    isbrandIconLoading,
    isbrandthumbLoading,
    brandtotal,
  } = useSelector((store) => store.brandAll);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { superCat } = useSelector((store) => store.superCategory);

  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [hot, setHot] = useState(Boolean(0));
  const [status, setStatus] = useState(Boolean(0));
  const [partners, setPartners] = useState(Boolean(0));
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [selectcat, setSelectcat] = useState([]);
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [brandId, setBrandId] = useState("");

  const dispatch = useDispatch();

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupcat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercatid
    );
    setSelectcat(catbysupcat);
  }, [supercatid]);

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  const params = useParams();
  useEffect(() => {
    const brandUrl = params.slugurl;

    const updatebrand = brandtotal.find((brand) => brand.slugUrl === brandUrl);
    setBrandId(updatebrand._id);
    setSlugUrl(updatebrand.slugUrl);
    setBrand(updatebrand.name);
    setHot(updatebrand.offers);
    setStatus(updatebrand.status);
    setPartners(updatebrand.partners);
    dispatch(updateBrandIcon(updatebrand.icon));
    dispatch(updateBrandThumbnail(updatebrand.thumbnail));
    dispatch(updateBrandBanner(updatebrand.banner));
    dispatch(updateBrandDesktop(updatebrand.desktopicon));
  }, []);
  const brandchange = (e) => {
    const name = e.target.value;
    setBrand(name);
    let slugurl = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^'/!{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slugurl);
  };

  const handlesubmit = async (e) => {
    if (!isbrandthumbLoading && brand !== "") {
      e.preventDefault();
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        offers: hot,
        status: status,
        partners: partners,
        thumbnail: brandthumb,
        icon: brandicon,
        desktopicon: branddesktop,
        banner: brandbanner,
        brandid: brandId,
      };
      const data = await dispatch(brandUpdate(formData));

      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("Brand Updated");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter valid details");
    }
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandAllimgageUploade({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Brand</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Brand</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={brand}
                        required
                        onChange={(e) => brandchange(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        SlugUrl *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="SlugUrl"
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="hot"
                          checked={hot === true}
                          onChange={(e) => {
                            setHot(e.target.checked);
                          }}
                        />
                        <Form.Label>Hot</Form.Label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="me-1"
                          value="status"
                          checked={status === true}
                          onChange={(e) => {
                            setStatus(e.target.checked);
                          }}
                        />
                        <Form.Label>offers</Form.Label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="ml-3"
                          value="partners"
                          checked={partners === true}
                          onChange={(e) => {
                            setPartners(e.target.checked);
                          }}
                        />
                        <Form.Label>Partners</Form.Label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label>Brand Thumbnail Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 200px * 200px
                      </p>
                      <div>
                        {isbrandthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={brandthumb} height={150} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Brand
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateBrand;
