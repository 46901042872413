import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";

import {
  groceryUpdate,
  groceryBannerNew,
  validateGrocerySlugUrl,
  updateGroceryBanner,
  updateGroceryIcon,
  updateGroceryThumbnail,
  updateGroceryDesktop,
  resetImage,
} from "../../../redux/grocery/groceySlice";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

const UpdateGroceryNew = () => {
  const { grocerysuperCat } = useSelector((store) => store.superCategory);
  const { grocerycategory } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const { brandtotal } = useSelector((store) => store.brandAll);
  const { grocerytotal } = useSelector((store) => store.groceryAll);

  const { categoryTagAll, iscategoryLoading } = useSelector(
    (store) => store.CategoryTagAll
  );
  const { subcategorytagAll, isSubCatTagLoading } = useSelector(
    (store) => store.SubCategoryTagAll
  );
  const { superCatTagAll } = useSelector((store) => store.SupercatTagAll);
  const { universaltagAll } = useSelector((store) => store.UniversalTagAll);
  const {
    groceryicon,
    grocerythumb,
    grocerybanner,
    grocerydesktop,
    isgrocerybanLoading,
  } = useSelector((store) => store.groceryAll);

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [grocery, setGrocery] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [about, setAbout] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [hsnCode, setHsnCode] = useState("");

  const [packsize, setPacksize] = useState("");
  const [costprice, setCostprice] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [sellingprice, setSellingprice] = useState("");
  const [gstsellprice, setGstsellprice] = useState("");
  const [mrp, setMrp] = useState("");
  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [packsizeoutofstock, setPacksizeutofstock] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [productoutofstock, setProductoutofstock] = useState(Boolean(0));
  const [recommended, setRecommended] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [pcb1, setPcb1] = useState(1);
  const [pcb2, setPcb2] = useState(2);
  const [pcb3, setPcb3] = useState(3);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");

  const [universalTags, setUniversalTags] = useState([]);
  const [supercattag, setSupercattag] = useState([]);
  const [categoryTag, setCategorytag] = useState([]);
  const [subcategorytag, setSubcategorytag] = useState([]);
  const [AlluniversalTags, setAllUniversalTags] = useState([]);
  const [Allsupercattag, setAllSupercattag] = useState([]);
  const [AllcategoryTag, setAllCategorytag] = useState([]);
  const [Allsubcategorytag, setAllSubcategorytag] = useState([]);
  const [error, setError] = useState(false);
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [errorslug, setErrorslug] = useState("");
  const [groceryId, setGroceryId] = useState("");
  const [mygroceryTotal, setmygroceryTotal] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetImage());
  }, []);

  const imageRemoveClick = (e, imagename, indexnumber) => {
    let imagearray = grocerybanner;
    imagearray = imagearray.filter((image) => image !== imagename);
    dispatch(updateGroceryBanner(imagearray));
  };

  const grocerychange = async (e) => {
    const name = e.target.value;
    setGrocery(name);
    setSlugUrl(name);
    setErrorslug("");
    let slugurlgroc = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^'/!{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

    setSlugUrl(slugurlgroc);
    if (slugurlgroc != "") {
      const slugverify = mygroceryTotal.filter(
        (supermart) => supermart.slugUrl === slugurlgroc
      );

      console.log(slugverify);
      setErrorslug("");
      if (slugverify.length > 0) {
        setErrorslug("Product alredy exist");
        // alert("Product alredy exist");
      } else {
        setCheckSlugUrl(slugurlgroc);
        setErrorslug("");
      }

      // let responce = await dispatch(validateGrocerySlugUrl(slugurlgroc));
      // if (responce.payload.success) {
      //   setErrorslug("Product alredy exist");
      //   alert("Product alredy exist");
      // } else {
      //   setCheckSlugUrl(slugurlgroc);
      //   setErrorslug("");
      // }
    }
  };

  // const discount = Math.round(((mrp - costprice) * 100) / mrp);

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: grocery.charAt(0).toUpperCase() + grocery.slice(1).toLowerCase(),
      slugUrl: slugUrl,
      superCategory: grocerysuperCat.name,
      superCategoryId: grocerysuperCat._id,
      category: category,
      categoryId: categoryid,
      subCategory: subcategory,
      subCategoryId: subcategoryid,
      brand: brand,
      about: about,
      ingredient: ingredient,
      productInfo: productinfo,
      dealoftheday: hotproducts,
      trending: trending,
      outOfStock: packsizeoutofstock,
      offers: offers,
      recommended: recommended,
      multi: multi,
      hsnCode: hsnCode,
      caskBack: cashback,
      tags: {
        universatTag: universalTags.join(", "),
        superCategoryTag: supercattag.join(", "),
        categoryTag: categoryTag.join(", "),
        subCategoryTag: subcategorytag.join(", "),
      },
      options: options,
      recommended: recommended,
      pcb1: pcb1,
      pcb2: pcb2,
      pcb3: pcb3,
      metaTitle: metatitle,
      metaKeyword: metakeyword,
      metaDesc: metadesc,
      groceryid: groceryId,
    };
    const data = await dispatch(groceryUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Grocery Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid]);

  const selectBrand = (evt) => {
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    const brandbycat = brandtotal.filter(
      (brand) => brand.categoryId === categoryid
    );

    const arraySort = [...brandtotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setCatbrand(arraySort);
  }, [categoryid]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const selectcategorytag = (evt) => {
    if (categoryTag.includes(evt.target.value)) {
      setCategorytag(
        categoryTag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setCategorytag((categoryTag) => [...categoryTag, evt.target.value]);
    }
  };

  useEffect(() => {
    const cattags = categoryTagAll.filter(
      (tag) => tag.categoryId === categoryid
    );

    setAllCategorytag(cattags);
  }, [categoryid, iscategoryLoading]);

  const selectsubcategorytag = (evt) => {
    if (subcategorytag.includes(evt.target.value)) {
      setSubcategorytag(
        subcategorytag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSubcategorytag((subcategorytag) => [
        ...subcategorytag,
        evt.target.value,
      ]);
    }
  };

  useEffect(() => {
    const subcattags = subcategorytagAll.filter(
      (tag) => tag.subCategoryId === subcategoryid
    );
    setAllSubcategorytag(subcattags);
  }, [subcategoryid, isSubCatTagLoading]);

  const selectuniversaltag = (evt) => {
    if (universalTags.includes(evt.target.value)) {
      setUniversalTags(
        universalTags.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setUniversalTags((universalTags) => [...universalTags, evt.target.value]);
    }
  };
  useEffect(() => {
    const unitag = universaltagAll;
    setAllUniversalTags(unitag);
  }, []);

  const selectsupercattag = (evt) => {
    if (supercattag.includes(evt.target.value)) {
      setSupercattag(
        supercattag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setSupercattag((supercattag) => [...supercattag, evt.target.value]);
    }
  };
  useEffect(() => {
    const superCatTag = superCatTagAll;
    setAllSupercattag(superCatTag);
  }, []);

  const bannerChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryBannerNew({ banner: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["thumbnail"] = grocerythumb;
      temp[index]["icon"] = groceryicon;
      temp[index]["desktopicon"] = grocerydesktop;
      temp[index]["productSlider"] = grocerybanner;
    }
    setOptions(temp);
  }, [grocerythumb, groceryicon, grocerydesktop, grocerybanner]);

  const [options, setOptions] = useState([
    {
      thumbnail: grocerythumb,
      icon: groceryicon,
      desktopicon: grocerydesktop,
      productSlider: grocerybanner,
      skuCode: "",
      barcode: "",
      optionName: "",
      costPrice: 0,
      gstCost: 0,
      sellingPrice: 0,
      gstSellPrice: 0,
      mrp: 0,
      discounts: 0,
      maximumQuantity: 10,
    },
  ]);

  const handleCheck = (index, e, selected) => {
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "mrp" || selected === "sellingPrice") &&
      temp[index]["mrp"] > 0 &&
      temp[index]["sellingPrice"] > 0 &&
      temp[index]["mrp"] > temp[index]["sellingPrice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const discounts = Math.round(
      ((temp[index]["mrp"] - temp[index]["sellingPrice"]) * 100) /
        temp[index]["mrp"]
    );

    temp[index]["discounts"] = discounts;

    setOptions(temp);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        thumbnail: grocerythumb,
        icon: groceryicon,
        desktopicon: grocerydesktop,
        productSlider: grocerybanner,
        skuCode: "",
        barcode: "",
        optionName: "",
        costPrice: 0,
        gstCost: 0,
        sellingPrice: 0,
        gstSellPrice: 0,
        mrp: 0,
        discounts: 0,
        maximumQuantity: 10,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };

  const params = useParams();

  useEffect(() => {
    const supermartUrl = params.slugurl;
    if (grocerytotal.length > 0) {
      const updatesupermart = grocerytotal.find(
        (supermart) => supermart.slugUrl === supermartUrl
      );

      if (updatesupermart) {
        console.log(updatesupermart._id);
        setGroceryId(updatesupermart._id);

        const groceryfilter = grocerytotal.filter(
          (product) => product._id !== updatesupermart._id
        );
        setmygroceryTotal(groceryfilter);
        let currentOptions = [];

        for (let index = 0; index < updatesupermart.options.length; index++) {
          dispatch(
            updateGroceryBanner(updatesupermart.options[index].productSlider)
          );
          dispatch(updateGroceryIcon(updatesupermart.options[index].icon));
          dispatch(
            updateGroceryThumbnail(updatesupermart.options[index].thumbnail)
          );
          dispatch(
            updateGroceryDesktop(updatesupermart.options[index].desktopicon)
          );

          currentOptions = [
            ...currentOptions,
            {
              thumbnail: updatesupermart.options[index].thumbnail,
              icon: updatesupermart.options[index].icon,
              desktopicon: updatesupermart.options[index].desktopicon,
              productSlider: updatesupermart.options[index].productSlider,
              skuCode: updatesupermart.options[index].skuCode,
              barcode: updatesupermart.options[index].barcode,
              optionName: updatesupermart.options[index].optionName,
              costPrice: updatesupermart.options[index].costPrice,
              gstCost: updatesupermart.options[index].gstCost,
              sellingPrice: updatesupermart.options[index].sellingPrice,
              gstSellPrice: updatesupermart.options[index].gstSellPrice,
              mrp: updatesupermart.options[index].mrp,
              discounts: updatesupermart.options[index].discounts,
              maximumQuantity: updatesupermart.options[index].maximumQuantity,
            },
          ];
        }
        setOptions(currentOptions);
        setGrocery(updatesupermart.name);
        setSlugUrl(updatesupermart.slugUrl);
        setCategory(updatesupermart.category);
        setCategoryid(updatesupermart.categoryId);
        setSubcategory(updatesupermart.subCategory);
        setSubcategoryid(updatesupermart.subCategoryId);
        setBrand(updatesupermart.brand);
        setCategorytag([updatesupermart.tags.categoryTag]);
        setAbout(updatesupermart.about);
        setIngredient(updatesupermart.ingredient);
        setProductinfo(updatesupermart.productInfo);
        setRecommended(updatesupermart.recommended);
        setHotproducts(updatesupermart.dealoftheday);
        setTrending(updatesupermart.trending);
        setPacksizeutofstock(updatesupermart.outOfStock);
        setOffers(updatesupermart.offers);
        setMulti(updatesupermart.multi);
        setCashback(updatesupermart.caskBack);
        setMetatitle(updatesupermart.metaTitle);
        setMetakeyword(updatesupermart.metaKeyword);
        setMetadesc(updatesupermart.metaDesc);
        setHsnCode(updatesupermart.hsnCode);
      }
    }
  }, [params.slugurl, grocerytotal]);

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update SuperMart</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update SuperMart</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={grocerycategory._id}
                        name={grocerycategory.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {grocerycategory.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            selected={data._id === subcategoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        name={catbrand.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {catbrand.map((data) => (
                          <option
                            selected={data.name === brand}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        required
                        value={grocery}
                        onChange={(e) => grocerychange(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errorslug}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        HSN Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter hsn code"
                        required
                        value={hsnCode}
                        onChange={(e) => setHsnCode(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">About</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        placeholder="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        placeholder="enter  Product Info....."
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Universal Tag </Form.Label>
                      {AlluniversalTags.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={universalTags.includes(tag.name)}
                              onChange={(e) => {
                                selectuniversaltag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </Col>
                    <Col>
                      <Form.Label>Super Category Tag </Form.Label>

                      {Allsupercattag.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={supercattag.includes(tag.name)}
                              onChange={(e) => {
                                selectsupercattag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </Col>
                    <Col>
                      <Form.Label>Category Tag </Form.Label>
                      {AllcategoryTag.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={categoryTag.includes(tag.name)}
                              onChange={(e) => {
                                selectcategorytag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </Col>
                    <Col>
                      <Form.Label>Sub category Tag </Form.Label>
                      {Allsubcategorytag.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={subcategorytag.includes(tag.name)}
                              onChange={(e) => {
                                selectsubcategorytag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <Row className="g-2  m-2"></Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="hotproducts"
                        checked={hotproducts === true}
                        onChange={(e) => {
                          setHotproducts(e.target.checked);
                        }}
                      />
                      <Form.Label>Deal of the day</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="trending"
                        className="me-1"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="packsizeoutofstock"
                        className="me-1"
                        checked={packsizeoutofstock === true}
                        onChange={(e) => {
                          setPacksizeutofstock(e.target.checked);
                        }}
                      />
                      <Form.Label>Pack Size Out Of Stock </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offers</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="multi"
                        checked={multi === true}
                        onChange={(e) => {
                          setMulti(e.target.checked);
                        }}
                      />
                      <Form.Label>Multi </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="recommended"
                        className="me-1"
                        checked={recommended === true}
                        onChange={(e) => {
                          setRecommended(e.target.checked);
                        }}
                      />
                      <Form.Label>Recommended </Form.Label>
                    </Col>

                    <Col>
                      <input
                        type="checkbox"
                        value="cashback"
                        className="me-1"
                        checked={cashback === true}
                        onChange={(e) => {
                          setCashback(e.target.checked);
                        }}
                      />
                      <Form.Label>Cashback </Form.Label>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Product Slider</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={bannerChange}
                        multiple
                      />
                      <p>
                        * The image width and height should be 600px * 600px
                      </p>
                      <div>
                        {isgrocerybanLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {/* {grocerybanner.map((data, index) => (
                              <img
                                src={data}
                                key={index}
                                width={150}
                                height={150}
                                alt="img"
                                className="mx-2"
                              />
                            ))}{" "} */}

                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                {grocerybanner.map((image, index) => (
                                  <div
                                    style={{
                                      position: "relative",
                                      height: "100px",
                                      width: "100px",
                                      margin: "5px",
                                    }}
                                  >
                                    <img src={image} height="100%" alt="img" />
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 3,
                                      }}
                                      onClick={(e) =>
                                        imageRemoveClick(e, image, index)
                                      }
                                    >
                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "20px",
                                        }}
                                      >
                                        X
                                      </h6>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Options</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              SKU Code
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.skuCode}
                              required
                              onChange={(e) => handleCheck(index, e, "skuCode")}
                            />
                            <div className="errorhandle mt-2">
                              {error && details?.skuCode.length <= 0 ? (
                                <label className="errorlabel">
                                  please input skucode *
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Barcode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.barcode}
                              required
                              onChange={(e) => handleCheck(index, e, "barcode")}
                            />
                            <div className="errorhandle mt-2">
                              {error && details?.barcode.length <= 0 ? (
                                <label className="errorlabel">
                                  please input barcode *
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Varient
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.optionName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "optionName")
                              }
                            />
                            <div className="errorhandle mt-2">
                              {error && details?.optionName.length <= 0 ? (
                                <label className="errorlabel">
                                  input varient *
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MaxQty
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.maximumQuantity}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "maximumQuantity")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Purches P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.costPrice}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "costPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.gstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "gstCost")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.sellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "sellingPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.gstSellPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "gstSellPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.mrp}
                              onChange={(e) => handleCheck(index, e, "mrp")}
                            />
                          </Col>
                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleNewRow}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className="card-footer text-center ">
                  {!errorslug.length > 0 &&
                  !isgrocerybanLoading &&
                  categoryid !== "" &&
                  subcategoryid !== "" &&
                  brand !== "" &&
                  grocery !== "" ? (
                    <button className="btn btn-primary me-2 " type="submit">
                      Update Supermart
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Update Supermart
                    </button>
                  )}

                  {/* <button
                      className="btn btn-danger"
                      onClick={clearClick}
                      type="reset"
                    >
                      Clear All
                    </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateGroceryNew;
