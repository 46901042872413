import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  categoryThumbnail,
  categoryIcon,
  categoryPost,
  categoryBanner,
  categoryDesktop,
  validateSlugUrl,
  categoryimgnew,
  resetCategoryImage,
} from "../../../redux/category/categorySlice";
import {
  shopPost,
  validateshopEmail,
  validateshopMobile,
} from "../../../redux/shop/ShopSlice";

const AddShop = () => {
  const { isAuth } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  const { superCat } = useSelector((store) => store.superCategory);

  const { catthumb, caticon, catdesktop, catbanner, isCatthumbLoading } =
    useSelector((store) => store.categoryAll);
  const dispatch = useDispatch();

  const [shopname, setShopname] = useState("");
  const [mobile, setMobile] = useState("");
  const [comformphoneNo, setComformphoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [uniqueemail, setUniqueEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [shopGst, setShopGst] = useState("");
  const [shopOwnername, SetShopOwnername] = useState("");
  const [role, SetRole] = useState("");
  const [shopStatus, SetShopStatus] = useState("");
  const [shopStatusText, SetShopStatusText] = useState("");
  const [shopAddress, SetShopAddress] = useState("");

  // Error message
  const [error, setError] = useState("");
  const [errormassage, setErrormassage] = useState("");
  const [errormsgClr, setErrormsgclr] = useState("red");
  const [errormassageEmail, setErrormassageEmail] = useState("");

  useEffect(() => {
    dispatch(resetCategoryImage());
  }, []);

  const verifyPhoneNumber = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobile(value);
    setErrormassage("");
    if (value.length === 10) {
      let responce = await dispatch(validateshopMobile(value));
      if (responce.payload.success) {
        setErrormassage("Number already exist");
      } else {
        setComformphoneNo(value);
        setErrormassage("");
      }
    } else {
      setErrormassage("Please enter valid number");
    }
  };

  const verifyemail = async (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrormsgclr("green");
    setErrormassageEmail("");
    if (value != "") {
      let responce = await dispatch(validateshopEmail(value));

      if (responce.payload.success) {
        setErrormassageEmail("Email already exist");
      } else {
        setUniqueEmail(value);
        setErrormassageEmail("");
      }
    }
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files[0]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryThumbnail({ shopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      shopname !== "" &&
      comformphoneNo !== "" &&
      uniqueemail !== "" &&
      password !== ""
    ) {
      const formData = {
        shopName:
          shopname.charAt(0).toUpperCase() + shopname.slice(1).toLowerCase(),
        //   shopId:,
        mobile: comformphoneNo,
        email: uniqueemail,
        password: password,
        shopGst: shopGst,
        shopOwnerName: shopOwnername,
        //   shopImage:,
        shopAddress: shopAddress,
      };

      const data = await dispatch(shopPost(formData));
      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("shop Added");
          setShopname("");
          setMobile("");
          setEmail("");
          setPassword("");
          setShopGst("");
          SetShopOwnername("");

          SetShopAddress("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Shop</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Shop</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Shop Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Shop Name"
                        required
                        value={shopname}
                        onChange={(e) => setShopname(e.target.value)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Mobile Number
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile Number"
                        required
                        minLength={10}
                        maxLength={10}
                        pattern="[6789][0-9]{9}"
                        value={mobile}
                        onChange={(e) => verifyPhoneNumber(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errormassage}
                      </p>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onChange={(e) => verifyemail(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errormassageEmail}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Password
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Shop Gst
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Shop Gst"
                        required
                        value={shopGst}
                        onChange={(e) => setShopGst(e.target.value)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Shop OwnerName
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Shop OwnerName"
                        required
                        value={shopOwnername}
                        onChange={(e) => SetShopOwnername(e.target.value)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    {/* <Col md>
                      <Form.Label>Shop Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 360px * 270px
                      </p>
                      <div>
                        {isCatthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={catthumb} height={100} alt="img" />
                        )}
                      </div>
                    </Col> */}
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Shop Address </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Shop Address"
                        style={{ height: "100px" }}
                        value={shopAddress}
                        onChange={(e) => SetShopAddress(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="card-footer ">
                  {shopname !== "" &&
                  mobile !== "" &&
                  email !== "" &&
                  password !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Add Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddShop;
