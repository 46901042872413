import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

const PasswordChange = () => {
  const { loginData } = useSelector((store) => store.auth);
  console.log(loginData, "loginData");

  const dispatch = useDispatch();

  const [oldPassword, setoldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [errormassageone, setErrormassageone] = useState("");
  const [comformphoneNo, setComformphoneNo] = useState("");
  const [errormsgClr, setErrormsgclr] = useState("red");

  const confirmpasswordChange = (e) => {
    const value = e.target.value;
    setConfirmpassword(value);
    setErrormassageone("");

    if (value != password) {
      setErrormsgclr("red");
      setErrormassageone("password not matched");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (confirmpassword != "") {
      const formData = {
        password: confirmpassword,
        mobile: comformphoneNo,
      };
      //   const data = await dispatch(adminPost(formData));

      //   if (!data) {
      //     <div></div>;
      //   } else {
      //     if (data.payload.success) {
      //       setConfirmpassword("");
      //       setPassword("");
      //     } else {
      //       console.log(data.error);
      //     }
      //   }
    } else {
      alert("please enter currect data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Admin Password Change </h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Admin Password Change </h5>
                </div>
                <div className="card-body">
                  <Row className="g-2 w-50 m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Old Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        required
                        value={oldPassword}
                        onChange={(e) => setoldPassword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        New Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        New Confirm Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=" Confirm Password"
                        required
                        value={confirmpassword}
                        onChange={(e) => confirmpasswordChange(e)}
                      />
                      <p
                        style={{ color: errormsgClr, fontWeight: 500 }}
                        className="mt-2"
                      >
                        {errormassageone}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center ">
                  {!errormassageone.length > 0 ? (
                    <button className="btn btn-primary me-2" type="submit">
                      Change Password
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Change Password
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default PasswordChange;
