import React, { useEffect, useState } from "react";

const Footer = () => {
  const [date, setDate] = useState(0);
  const getYear = () => setDate(new Date().getFullYear());
  useEffect(() => {
    getYear();
  }, []);

  return (
    <div>
      <footer className="main-footer" style={{backgroundColor:'#4d44b5',color:'#fff'}}>
        <strong>
          Copyright © {date} <a className="px-2" >FarmerAck</a>
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
}
export default Footer;
