import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";

function ForgetPassword() {
  return (
    <>
      <div className="hold-transition register-page">
        <div className="register-box col-sm-5">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="." className="h1">
                <b>Salesbeez</b>
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg d-flex justify-content-center">
                Sign in to start your session
              </p>
              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    //   value={email}
                    //   onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <BsFillEnvelopeFill />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    //   value={password}
                    //   onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <AiFillLock />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Comform Password"
                    //   value={password}
                    //   onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <AiFillLock />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button type="submit" className="btn btn-primary ">
                      Update Pasword
                    </button>
                  </div>
                  <div className="col-6">
                    <Link to="/">
                      <button type="submit" className="btn btn-primary ">
                        Login
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
