//AddProduct

import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { Button } from "react-bootstrap";
import {
  allProductImagesUploade,
  productPost,
  updateProductBanner,
  validateProductSlugUrl,
} from "../../../redux/product/ProductSlice";

const AddProduct = () => {
  const { superCat } = useSelector((store) => store.superCategory);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const { brandtotal } = useSelector((store) => store.brandAll);

  const {
    producticon,
    productthumb,
    productbanner,
    productdesktop,
    isproductbanLoading,
  } = useSelector((store) => store.Product);

  const [newgrocerythumb, setnewgrocerythumb] = useState("");
  const [newgroceryicon, setnewgroceryicon] = useState("");
  const [newgrocerybanner, setnewgrocerybanner] = useState([]);
  const [newgrocerydesktop, setnewgrocerydesktop] = useState("");

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [grocery, setGrocery] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [about, setAbout] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [productinfo, setProductinfo] = useState("");

  const [packsize, setPacksize] = useState("");
  const [costprice, setCostprice] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [sellingprice, setSellingprice] = useState("");
  const [gstsellprice, setGstsellprice] = useState("");
  const [Mrp, setMrp] = useState("");

  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [recommended, setRecommended] = useState(Boolean(0));

  const [packsizeoutofstock, setPacksizeutofstock] = useState(Boolean(0));
  const [productoutofstock, setProductoutofstock] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [Single, setSingle] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [pcb1, setPcb1] = useState(1);
  const [pcb2, setPcb2] = useState(2);
  const [pcb3, setPcb3] = useState(3);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [hsnCode, setHsnCode] = useState("");

  const [universalTags, setUniversalTags] = useState([]);
  const [supercattag, setSupercattag] = useState([]);
  const [categoryTag, setCategorytag] = useState([]);
  const [subcategorytag, setSubcategorytag] = useState([]);
  const [AlluniversalTags, setAllUniversalTags] = useState([]);
  const [Allsupercattag, setAllSupercattag] = useState([]);
  const [AllcategoryTag, setAllCategorytag] = useState([]);
  const [Allsubcategorytag, setAllSubcategorytag] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [showhide, setShowhide] = useState("");
  const [error, setError] = useState(false);
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [errorslug, setErrorslug] = useState("");

  const [shortName, setShortName] = useState("");
  const [NoOfItemsInside, setNoOfItemsInside] = useState("");
  const [manufacturerDetails, setManufacturerDetails] = useState("");
  const [packersDetails, setPackersDetails] = useState("");
  const [Import, setImport] = useState("");
  const [model, setModel] = useState("");
  const [returnDays, setReturnDays] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [otherSpecification, setOtherSpecification] = useState("");
  const [OutOfStack, setOutOfStack] = useState(Boolean(0));
  const [prime, setprime] = useState(Boolean(0));
  const [colours, setSetColours] = useState([]);
  const [colour, setSetColour] = useState("");
  const [colourerror, setSetColourError] = useState("");

  const [options, setOptions] = useState([
    {
      thumbnail: productthumb,
      icon: producticon,
      desktopicon: productdesktop,
      productSlider: productbanner,
      optionName: "",
      optionName: "",
      CostPrc: 0,
      GstCost: 0,
      SellingPrice: 0,
      GstSellPrc: 0,
      prime: prime,
      Mrp: 0,
      Discount: 0,
      prime: true,
    },
  ]);
  const dispatch = useDispatch();

  const grocerychange = async (e) => {
    const name = e.target.value;
    setGrocery(name);
    setSlugUrl(name);
    setErrorslug("");
    let slugurlgroc = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^'!/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

    setSlugUrl(slugurlgroc);
    if (slugurlgroc != "") {
      let responce = await dispatch(validateProductSlugUrl(slugurlgroc));
      if (responce.payload.success) {
        setErrorslug("Product already exist");
      } else {
        setCheckSlugUrl(slugurlgroc);
        setErrorslug("");
      }
    }
  };

  const addColourClick = (e) => {
    e.preventDefault();
    setSetColourError("");
    if (colour !== "") {
      const verifycolor = colours.find((data) => data === colour);
      if (!verifycolor) {
        const colors = [...colours, colour];
        setSetColours([...colors]);
        setSetColour("");
      } else {
        setSetColourError("this colour already exist");
      }
    } else {
      setSetColourError("Please Enter Value...");
    }
  };

  const removeColorClick = (data, index) => {
    const color = colours.filter((col) => col !== data);
    setSetColours([...color]);
  };

  const imageRemoveClick = (e, imagename, indexnumber) => {
    let imagearray = productbanner;
    imagearray = imagearray.filter((image) => image !== imagename);
    dispatch(updateProductBanner(imagearray));
  };

  // const discount = Math.round(((Mrp - costprice) * 100) / Mrp);

  const handlesubmit = async (e) => {
    e.preventDefault();

    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["thumbnail"] = productthumb;
      temp[index]["icon"] = producticon;
      temp[index]["desktopicon"] = productdesktop;
      temp[index]["productSlider"] = productbanner;
    }

    let myoptions = [...temp];
    myoptions.forEach((option, index) => {
      if (
        option.optionName === "" ||
        option.SellingPrice === 0 ||
        option.Mrp === 0
      ) {
        myoptions.splice(index, 1);
      }
    });

    var date = new Date();
    date.setDate(date.getDate() + 7);

    if (
      grocery !== "" &&
      brand !== "" &&
      myoptions.length >= 1 &&
      !isproductbanLoading
    ) {
      const formData = {
        ItemName:
          grocery.charAt(0).toUpperCase() + grocery.slice(1).toLowerCase(),
        SlugUrl: slugUrl,
        shortName: shortName,
        superCategory: "Electronic",
        superCategoryId: "651a897c8ea29c740e761b9f",
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        Brand: brand,
        Title: metatitle,
        KeyWords: metakeyword,
        Description: metadesc,
        About: about,
        ProductInfo: productinfo,

        NoOfItemsInside: NoOfItemsInside,
        manufacturerDetails: manufacturerDetails,
        packersDetails: packersDetails,
        Import: Import,
        model: model,
        returnDays: date,
        termsConditions: termsConditions,
        otherSpecification: otherSpecification,
        Recommends: recommended,
        HotProducts: hotproducts,
        Trending: trending,
        Offers: offers,
        OutOfStack: productoutofstock,
        Multi: multi,
        Single: Single,
        caskBack: cashback,
        Pcb1: pcb1,
        Pcb2: pcb2,
        Pcb3: pcb3,
        options: myoptions,
        Colour: colours,
      };
      console.log(formData, 'formData');

      const data = await dispatch(productPost(formData));
      console.log(data.payload, 'data.payload');

      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          // alert("Grocery Added");
          setSlugUrl("");
          setGrocery("");
          setAbout("");
          setIngredient("");
          setProductinfo("");
          setPacksize("");
          setCostprice("");
          setGstcost("");
          setSellingprice("");
          setGstsellprice("");
          setMrp("");
          setPcb1("");
          setPcb2("");
          setPcb3("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setShortName("");
          setNoOfItemsInside("");
          setPackersDetails("");
          setImport("");
          setModel("");
          setTermsConditions("");
          setOtherSpecification("");
          setHotproducts(Boolean(0));
          setTrending(Boolean(0));
          setOffers(Boolean(0));
          setRecommended(Boolean(0));
          setProductoutofstock(Boolean(0));
          setMulti(Boolean(0));
          setSingle(Boolean(0));
          setCashback(Boolean(0));
          setOptions([
            {
              optionName: "",
              CostPrc: 0,
              GstCost: 0,
              SellingPrice: 0,
              GstSellPrc: 0,
              Mrp: 0,
              Discount: 0,
              prime: true,
            },
          ]);
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else if (myoptions.length === 0) {
      alert("please add Options value correctly ");
    } else if (brand === "") {
      alert("please select Brand ");
    } else if (isproductbanLoading) {
      alert("please select Image");
    } else if (grocery === "") {
      alert("please enter unique product name");
    } else {
      alert("please check the data");
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.length > 0 && subcategorytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid]);

  const selectBrand = (evt) => {
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    const brandbycat = brandtotal.filter(
      (brand) => brand.categoryId === categoryid
    );
    const arraySort = [...brandtotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setCatbrand(arraySort);
  }, [categoryid]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const bannerChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(allProductImagesUploade({ banner: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    setnewgrocerybanner(productbanner);
    setnewgrocerydesktop(productdesktop);
    setnewgroceryicon(producticon);
    setnewgrocerythumb(productthumb);

    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["thumbnail"] = newgrocerythumb;
      temp[index]["icon"] = newgroceryicon;
      temp[index]["desktopicon"] = newgrocerydesktop;
      temp[index]["productSlider"] = newgrocerybanner;
    }
    setOptions(temp);
  }, [productthumb, producticon, productdesktop, productbanner]);

  const handleCheck = (index, e, selected) => {
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "Mrp" || selected === "SellingPrice") &&
      temp[index]["Mrp"] > 0 &&
      temp[index]["SellingPrice"] > 0 &&
      temp[index]["Mrp"] > temp[index]["SellingPrice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const Discount = Math.round(
      ((temp[index]["Mrp"] - temp[index]["SellingPrice"]) * 100) /
      temp[index]["Mrp"]
    );
    temp[index]["Discount"] = Discount;

    setOptions(temp);
  };

  const handleNewRow = (e) => {
    setOptions([
      ...options,
      {
        thumbnail: productthumb,
        icon: producticon,
        desktopicon: productdesktop,
        productSlider: productbanner,
        optionName: "",
        optionName: "",
        CostPrc: 0,
        GstCost: 0,
        SellingPrice: 0,
        GstSellPrc: 0,
        Mrp: 0,
        Discount: 0,
        prime: false,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Product</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="errorhandle mt-2">
                        {error && categorytotal.length <= 0 ? (
                          <label className="errorlabel">
                            Select Category *
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.length > 0 && subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="errorhandle mt-2">
                        {error && subCat.length <= 0 ? (
                          <label className="errorlabel">
                            Select Brand <span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        name={brandtotal.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {brandtotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="errorhandle mt-2">
                        {error && brandtotal.length <= 0 ? (
                          <label className="errorlabel">
                            Select Brand <span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name....."
                        required
                        value={grocery}
                        onChange={(e) => grocerychange(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errorslug}
                      </p>
                      <div className="errorhandle mt-2">
                        {error && grocery.length <= 0 ? (
                          <label className="errorlabel">
                            please input Product Name *
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Short Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Product Short Name"
                        required
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        HSN Code <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter hsn code"
                        required
                        value={hsnCode}
                        onChange={(e) => setHsnCode(e.target.value)}
                        className="errorClass"
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">About</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter about"
                        style={{ height: "100px" }}
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>

                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter product info"
                        style={{ height: "100px" }}
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        No Of Items Inside{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter No Of Items Inside"
                        required
                        value={NoOfItemsInside}
                        onChange={(e) => setNoOfItemsInside(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Manufacturer Details{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter  Manufacturer Details"
                        required
                        value={manufacturerDetails}
                        onChange={(e) => setManufacturerDetails(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Packers Details <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Packers Details"
                        required
                        value={packersDetails}
                        onChange={(e) => setPackersDetails(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Import of Origin
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Import"
                        required
                        value={Import}
                        onChange={(e) => setImport(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Model <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Model"
                        required
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Terms & Conditions
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Terms & Conditions"
                        style={{ height: "100px" }}
                        value={termsConditions}
                        onChange={(e) => setTermsConditions(e.target.value)}
                      />
                    </Col>

                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Other Specification
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter  Other Specification"
                        style={{ height: "100px" }}
                        value={otherSpecification}
                        onChange={(e) => setOtherSpecification(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2"></Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">
                          Hot Products {hotproducts}
                        </Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="hotproducts"
                            type="checkbox"
                            value="hotproducts"
                            checked={hotproducts === true}
                            onChange={(e) => {
                              setHotproducts(e.target.checked);
                            }}
                          />
                          <label
                            className="tgl-btn"
                            htmlFor="hotproducts"
                          ></label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Trending</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="Trending"
                            type="checkbox"
                            value="Trending"
                            checked={trending === true}
                            onChange={(e) => {
                              setTrending(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="Trending"></label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Recommended</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="recommended"
                            type="checkbox"
                            value="recommended"
                            checked={recommended === true}
                            onChange={(e) => {
                              setRecommended(e.target.checked);
                            }}
                          />
                          <label
                            className="tgl-btn"
                            htmlFor="recommended"
                          ></label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Offers</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="offers"
                            type="checkbox"
                            value="offers"
                            checked={offers === true}
                            onChange={(e) => {
                              setOffers(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="offers"></label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Multi</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="Multi"
                            type="checkbox"
                            value="Multi"
                            checked={multi === true}
                            onChange={(e) => {
                              setMulti(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="Multi"></label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Single</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="Single"
                            type="checkbox"
                            value="Single"
                            checked={Single === true}
                            onChange={(e) => {
                              setSingle(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="Single"></label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">
                          Product Out Of Stock
                        </Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="productoutofstock"
                            type="checkbox"
                            value="productoutofstock"
                            checked={productoutofstock === true}
                            onChange={(e) => {
                              setProductoutofstock(e.target.checked);
                            }}
                          />
                          <label
                            className="tgl-btn"
                            htmlFor="productoutofstock"
                          ></label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Cashback</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="Cashback"
                            type="checkbox"
                            value="Cashback"
                            checked={cashback === true}
                            onChange={(e) => {
                              setCashback(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="Cashback"></label>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>
                        Product Slider <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={(e) => bannerChange(e)}
                        multiple
                        required
                      />
                      <p>
                        * The image width and height should be 600px * 600px
                      </p>
                      <div>
                        {isproductbanLoading ? (
                          <div style={{ color: "green" }}>
                            {/* Image Uploading... */}
                          </div>
                        ) : (
                          // <div>
                          //   {productbanner.map((data, index) => (
                          //     <img
                          //       src={data}
                          //       key={index}
                          //       width={150}
                          //       height={150}
                          //       alt="img"
                          //       className="mx-2"
                          //     />
                          //   ))}{" "}
                          // </div>
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {productbanner.map((image, index) => (
                                <div
                                  style={{
                                    position: "relative",
                                    height: "100px",
                                    width: "100px",
                                    margin: "5px",
                                  }}
                                  key={index}
                                >
                                  <img src={image} height="100%" alt="img" />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 3,
                                    }}
                                    onClick={(e) =>
                                      imageRemoveClick(e, image, index)
                                    }
                                  >
                                    <h6
                                      style={{
                                        fontWeight: "bold",
                                        color: "red",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                    >
                                      X
                                    </h6>
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Colour <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type="text"
                          placeholder="Enter Colour"
                          value={colour}
                          onChange={(e) => setSetColour(e.target.value)}
                        />
                        <button
                          className="btn btn-primary me-2 mx-3 "
                          onClick={(e) => addColourClick(e)}
                        >
                          Add
                        </button>
                      </div>

                      {colours.length > 0 ? (
                        <>
                          <div>
                            <h6 style={{ color: "red" }}>{colourerror}</h6>
                            {colours.map((data, index) => (
                              <h6>
                                {data}{" "}
                                <span
                                  className="mx-1"
                                  onClick={() => removeColorClick(data, index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  X
                                </span>
                              </h6>
                            ))}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Options</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              optionName <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.optionName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "optionName")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Purches P <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.CostPrc}
                              required
                              onChange={(e) => handleCheck(index, e, "CostPrc")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              GST Cost <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.GstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "GstCost")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.SellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "SellingPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.GstSellPrc}
                              onChange={(e) =>
                                handleCheck(index, e, "GstSellPrc")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.Mrp}
                              onChange={(e) => handleCheck(index, e, "Mrp")}
                            />
                          </Col>
                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              ColourName <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.Colour}
                              required
                              onChange={(e) => handleCheck(index, e, "Colour")}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={(e) => handleNewRow(e)}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className="card-footer text-center ">
                  {!errorslug.length > 0 &&
                    !isproductbanLoading &&
                    categoryid !== "" &&
                    subcategoryid !== "" &&
                    brand !== "" &&
                    grocery !== "" ? (
                    <button className="btn btn-primary me-2 " type="submit">
                      Add Product
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Add Product
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddProduct;
