import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  superCat:[],
  grocerysuperCat:[],
  electronicssuperCat: [],
  clothingsuperCat:[],
  isLoading: true,
  supercatLoading: true,
  delSupercatLoading: true,
};
export const getSuperCategory = createAsyncThunk(
  "SuperCategory/getSuperCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/supercategory/all`;
      const resp = await axios(url);
      return resp.data.supercategories;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategories Not Found");
    }
  }
);

export const postSuperCategory = createAsyncThunk(
  "superCategory/postSuperCategory",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);
export const updateSuperCategory = createAsyncThunk(
  "SuperCategory/updateSuperCategory",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/${formData.supercatid}`;
      const resp = await axios.put(url, formData, config);
      
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategory Not create");
    }
  }
);

export const superCategoryDelete = createAsyncThunk(
  "superCategory/superCategoryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/supercategory/${id}`;
      const resp = await axios.delete(url,id , config);
      console.log(resp.data);
  const myreturn ={
        success:resp.data.success,
        id:id,
      }
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("supercategory Not create");
    }
  }
);

const SuperCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  extraReducers: {
    [getSuperCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [getSuperCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.superCat = action.payload;

      state.grocerysuperCat = action.payload.find(
        (supercategory) => supercategory.name === "supermart"
      );

      state.electronicssuperCat = action.payload.find(
        (supercategory) => supercategory.name === "electronics"
      );

      state.clothingsuperCat = action.payload.find(
        (supercategory) => supercategory.name === "clothing"
      );
    },
    [getSuperCategory.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [postSuperCategory.pending]: (state) => {
      state.supercatLoading = true;
    },

    [postSuperCategory.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.superCat = [...state.superCat, action.payload.supercategory];
      }
      state.supercatLoading = false;
    },

    [postSuperCategory.rejected]: (state, action) => {
      state.supercatLoading = true;
    },


    [updateSuperCategory.pending]: (state) => {
      state.supercatLoading = true;
    },

    [updateSuperCategory.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.superCat = state.superCat.filter((supercategory) => supercategory._id !== action.payload.supercategory._id);
        state.superCat = [...state.superCat, action.payload.supercategory];
      }
      state.supercatLoading = false;
    },

    [updateSuperCategory.rejected]: (state, action) => {
      state.supercatLoading = true;
    },

    [superCategoryDelete.pending]: (state) => {
      state.delSupercatLoading = true;
    },

    [superCategoryDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.superCat = state.superCat.filter((supercategory) => supercategory._id !== action.payload.id);
      }
      state.delSupercatLoading = false;
    },

    [superCategoryDelete.rejected]: (state, action) => {
      state.delSupercatLoading = true;
    },

  },
});

export default SuperCategorySlice.reducer;
