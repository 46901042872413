import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { updateOrder } from "../../redux/order/orderSlice";
import BeatLoader from "react-spinners/BeatLoader";

import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { Modal, ModalHeader } from "reactstrap";

const SubscriptionOrderReceived = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const [filterOrder, setFilterOrder] = useState([]);
    const [orders, setOrders] = useState([]);
    const [temploading, setTempLoading] = useState(true);

    const [selectOrder, setSelectOrder] = useState("");
    const [model, setModel] = useState(false);
    const [cancelmodel, setCancelModel] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${Baseurl}/api/v1/subscription-order/orderreceived`;
            const fetchOrder = await axios.get(url);
            if (fetchOrder.data.success === true) {
                const orderTotal = fetchOrder.data.orders;
                setOrders(orderTotal.reverse());
                setTempLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const result = orders.filter((order) => {
            return order._id.toLowerCase().slice(18).match(search.toLowerCase());
        });
        setFilterOrder(result);
    }, [search, orders]);

    const nextProcesssClick = (e, order) => {
        setModel(true);
        setSelectOrder(order);
    };
    const cancelClick = (e, order) => {
        setCancelModel(true);
        setSelectOrder(order);
    };

    const updateClick = async (e) => {

        const formData = {
            Status: 5,
            StatusText: 'Order Delivered'
        };
        console.log(formData,'formData');
        const config = {
            Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/subscription-order/updateOrder/${selectOrder._id}`;
        console.log(url, 'url');
        const resp = await axios.put(url, formData, config);
        if (resp.data.success) {
            const finalOrder = orders.filter((order) => order._id !== selectOrder._id);
            setFilterOrder(finalOrder);
            setModel(false);
            window.location.reload()
        }
    };

    const deleteClick = async (e) => {
        var date = new Date();

        date.setDate(date.getDate());

        if (selectOrder.hasOwnProperty("OrderprocessDate")) {
            var OrderprocessDate = {
                OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
                OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
                OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
                OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
                OrderCancelledDate: date,
            };
        } else {
            var OrderprocessDate = {};
        }

        const formData = {
            Status: 0,
            StatusText: "Order Cancelled",
            orderid: selectOrder._id,
            remark: {
                OrderCancelRemark: "Your delivery is cancelled",
            },
            OrderprocessDate: OrderprocessDate,
        };
        dispatch(updateOrder(formData));

        const finalOrder = orders.filter((order) => order._id !== selectOrder._id);
        setFilterOrder(finalOrder);
        setCancelModel(false);
    };

    const columns = [
        {
            field: "id",
            headerName: "S. No",
            filterable: false,
            renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "Order ID",
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
                        {/* <Link to={`/admin/orderdetails/${params.row._id}`}>
                        </Link> */}
                            {params.row._id.slice(18)}
                    </div>
                );
            },
        },
        {
            field: "ClientId",
            width: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
                        {params.row.ClientId.slice(18)}
                    </div>
                );
            },
        },

        {
            field: "ClientName",
            headerName: "ClientName",
            width: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "Order DateTime",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column">
                        <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
                        <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
                    </div>
                );
            },
        },
        {
            field: "Address",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column" style={{ width: '200px', flexWrap: 'wrap' }}>
                        <div>{`${params.row.shippingInfo.HNo}`}</div>
                        <div>{`${params.row.shippingInfo.StreetDet},${params.row.shippingInfo.address},`}</div>
                        <div>{`${params.row.shippingInfo.state},${params.row.shippingInfo.country},${params.row.shippingInfo.pinCode}`}</div>
                    </div>
                );
            },
        },
        {
            field: "WhatsApp",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div
                        className="d-flex m-auto flex-column"
                        onClick={() =>
                            window.open(
                                `https://api.whatsapp.com/send/?phone=%2B91${params.row.Mobile}&text&type=phone_number&app_absent=0`
                            )
                        }
                        target="_blank"
                    >
                        <h6 style={{ color: "#007bff", cursor: "pointer" }}>WhatsApp</h6>
                    </div>
                );
            },
        },

        {
            field: "ACTIONS",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex  flex-column my-3">
                        <Button
                            variant="contained"
                            onClick={(e) => nextProcesssClick(e, params.row)}
                            style={{ background: "#556ee6" }}
                        >
                            Delivered
                        </Button>
                    </div>
                );
            },
        },

        {
            field: "TotalAmount",
            headerName: "Amount",
            width: 120,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "NetPayble",
            headerName: "NetPayable",
            width: 120,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "PaymentMode",
            headerName: "PaymentMode",
            width: 120,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "PaymentStatus",
            headerName: "PaymentStatus",
            width: 120,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "Mobile",
            headerName: "Mobile",
            width: 130,
            headerAlign: "center",
            align: "center",
        },
    ];
    return (
        <>
            <Fragment>
                <div className="content-wrapper ">
                    <div className="card me-2 ms-2">
                        <div className="card-footer">
                            <h5>ORDER PACKING</h5>
                        </div>
                        <div className="card-body">
                            <Row className="g-2  m-2 w-25">
                                <Col md>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search by orderId"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        style={{ textTransform: "uppercase" }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {temploading ? (
                            <>
                                <div className="d-flex justify-content-center loadingMain">
                                    <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                                        Order is being fetched
                                    </h4>
                                    <BeatLoader
                                        color={"#36d7b7"}
                                        loading={temploading}
                                        size={10}
                                        className="mt-2 mx-2"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{ height: 650, width: "100%" }}
                                    className="d-flex m-auto text-align-center"
                                >
                                    <DataGrid
                                        rows={filterOrder}
                                        columns={columns}
                                        getRowId={(row) => row._id}
                                        rowHeight={100}
                                        getRowHeight={() => "auto"}
                                        pageSize={20}
                                        rowsPerPageOptions={[5]}
                                        slots={{
                                            toolbar: GridToolbar,
                                        }}
                                        sx={{
                                            ".MuiDataGrid-columnSeparator": {
                                                display: "none",
                                            },
                                            "&.MuiDataGrid-root": {
                                                border: "none",
                                            },
                                        }}
                                    />
                                </div>

                                <Modal size="md" isOpen={model} toggle={() => setModel(!model)}>
                                    <ModalHeader>
                                        <div className=" ">
                                            <div className="col-12 ">
                                                <div className="col-12">
                                                    <h3>
                                                        Have You Delivered{" "}
                                                        <span style={{ color: "red" }}>
                                                            The Order
                                                        </span>{" "}
                                                        with orderId:
                                                        {selectOrder !== "" ? (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        color: "#dc3545",
                                                                        textTransform: "uppercase",
                                                                    }}
                                                                >
                                                                    {selectOrder._id.slice(18)}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </h3>
                                                    <div className="col-12 mt-3 ">
                                                        <div className="col-6 d-flex justify-content-between">
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                className="mx-1 px-5"
                                                                onClick={() => setModel(false)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className="mx-1 px-5"
                                                                onClick={(e) => updateClick(e)}
                                                            >
                                                                Process
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalHeader>
                                </Modal>
                                <Modal
                                    size="md"
                                    isOpen={cancelmodel}
                                    toggle={() => setCancelModel(!cancelmodel)}
                                >
                                    <ModalHeader>
                                        <div className=" ">
                                            <div className="col-12 ">
                                                <div className="col-12">
                                                    <h3>
                                                        Do You Want to{" "}
                                                        <span style={{ color: "red" }}>Cancel</span> this
                                                        orderId:
                                                        {selectOrder !== "" ? (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        color: "#dc3545",
                                                                        textTransform: "uppercase",
                                                                    }}
                                                                >
                                                                    {selectOrder._id.slice(18)}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </h3>
                                                    <div className="col-12 mt-3 ">
                                                        <div className="col-6 d-flex justify-content-between">
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                className="mx-1 px-5"
                                                                onClick={() => setCancelModel(false)}
                                                            >
                                                                Back
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className="mx-1 px-5"
                                                                onClick={(e) => deleteClick(e)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalHeader>
                                </Modal>
                            </>
                        )}
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default SubscriptionOrderReceived;
