import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdOutlineCategory } from "react-icons/md";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../img/salesbeezlogo.jpg";
import { useSelector } from "react-redux";

const Menu = () => {
  const { isAuth, loginData } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  return (
    <>
      {isAuth ? (
        <div>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/admin" className="brand-link">
              <img
                src={logo}
                alt="Admin"
                className="brand-image img-circle elevation-3 "
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">Super Admin</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
              <nav className="mt-2">
                {/*================================ Dashboard ==========================*/}
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item has-treeview">
                    <Link to="/admin" className="nav-link">
                      <span>
                        <FaHome />
                      </span>
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  {/*============================ product Setting Start ================================*/}

                  <li className="nav-item has-treeview">
                    <a className="nav-link">
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Add-Edit
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <BiCategoryAlt />
                          </span>
                          <p>
                            Category
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/addcategory" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add category</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/bulk-category-uploade"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Bulk category</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/listcategory" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List category</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <BiCategoryAlt />
                          </span>
                          <p>
                            Sub Category
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/addsubcategory"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Sub-category</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/bulk-sub-category-uploade"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Bulk Sub-category</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/listsubcategory"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Sub-category</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <BiCategoryAlt />
                          </span>
                          <p>
                            SuperMart
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/add-products" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Product</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/list-products"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Product</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/products-bulk-uploade"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Product Bulk Upload</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/options-bulk-uploade"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Options Bulk Upload</p>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Brand
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/addbrand">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Brand</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="/admin/brand-bulkupload"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Bulk Brand</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/listbrand">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Brand</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Slider
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/add-slider">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Slider</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  {/*============================ product Setting end ================================*/}
                  {/*============================ Orders Details start ================================*/}
                  <li className="nav-item has-treeview">
                    <a className="nav-link">
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Orders Details
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/bookedorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Booked Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/processingorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Processing Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/dispatchorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Dispatch Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/outfordeliveryorder"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Out For Delivery</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/deliveredorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Delivered Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/cancelledorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Cancelled Order</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/*============================ Order Details end ================================*/}
                  {/*============================ Subscription Orders Details start ================================*/}
                  <li className="nav-item has-treeview">
                    <a className="nav-link">
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Subscription Details
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/allSubscription" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Subscriptions</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/subcriptionList" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Tomorrow Subscriptions</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/subscriptionOrderReceived"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Received Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/subscriptionOrderDelivered" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Delivered Order</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/*============================Subscription  Order Details end ================================*/}

                  <li className="nav-item has-treeview">
                    <a className="nav-link">
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Admin Settings
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/register" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>New Admin Registration</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/password-change" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Password Change</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Menu;
