import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  categoryThumbnail,
  categoryIcon,
  categoryPost,
  categoryBanner,
  categoryDesktop,
  validateSlugUrl,
  categoryimgnew,
  resetCategoryImage,
} from "../../../redux/category/categorySlice";

const AddCategory = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);

  const { superCat, grocerysuperCat } = useSelector(
    (store) => store.superCategory
  );
  console.log(superCat, 'superCat');
  console.log(grocerysuperCat, 'grocerysuperCat');

  const {
    catthumb,
    caticon,
    catdesktop,
    catbanner,
    isCatIconLoading,
    isCatthumbLoading,
    isCatbanLoading,
    isCatdeskLoading,
    categorytotal,
    checkSlugurl,
  } = useSelector((store) => store.categoryAll);

  const [categroy, setCategroy] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCategoryImage());
  }, []);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setSlugUrl(value);
    setCategroy(value);
    setError("");
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
    if (slug != "") {
      let responce = await dispatch(validateSlugUrl(slug));

      if (responce.payload.success) {
        setError("category alredy exist");
        alert("category alredy exist");
        setCheckSlugUrl("");
      } else {
        setCheckSlugUrl(slug);
        setError("");
      }
    }
  };

  const categorychange = (e) => {
    const name = e.target.value;
    setCategroy(name);
    let slug = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      categroy !== "" &&
      !isCatthumbLoading &&
      !isCatbanLoading &&
      checkslugUrl !== ""
    ) {
      const formData = {
        name:
          categroy.charAt(0).toUpperCase() + categroy.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        superCategoryId: supercatid,
        superCategory: supercat,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        thumbnail: catthumb,
        icon: caticon,
        desktopicon: catdesktop,
        banner: catbanner,
      };
      const data = await dispatch(categoryPost(formData));

      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("Category Added");
          setCategroy("");
          setSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setSupercat("");
          setSupercatid("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct data");
    }
  };

  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    console.log(files[0]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryThumbnail({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const bannerChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryBanner({ banner: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const IconChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryIcon({ icon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryDesktop({ desktopicon: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCat(e);
                        }}
                        value={superCat._id}
                        name={superCat.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        required
                        value={categroy}
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Slug URL
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter slug url"
                        required
                        value={slugUrl}
                        disabled
                        onChange={(e) => verifyslugurl(e)}
                      />
                       <p style={{ color: "red" }} className="mt-2">
                          {error}
                        </p>
                    </Col> */}
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Category Website Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 360px * 270px
                      </p>
                      <div>
                        {isCatthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={catthumb} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Category Banners Image</Form.Label>
                      <Form.Control type="file" onChange={bannerChange} />

                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 320px
                      </p>
                      <div>
                        {isCatbanLoading ? (
                          <div></div>
                        ) : (
                          <img src={catbanner} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Category Icon Image</Form.Label>
                      <Form.Control type="file" onChange={IconChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 120px * 120px
                      </p>
                      <div>
                        {isCatIconLoading ? (
                          <div></div>
                        ) : (
                          <img src={caticon} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Category Mobile Image</Form.Label>
                      <Form.Control type="file" onChange={mobileChange} />

                      <p className="text-center mt-2">
                        * The image width and height should be 252px * 168px
                      </p>
                      <div>
                        {isCatdeskLoading ? (
                          <div></div>
                        ) : (
                          <img src={catdesktop} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {!isCatthumbLoading &&
                    !isCatbanLoading &&
                    categroy !== "" &&
                    !error.length > 0 ? (
                    <button className="btn btn-primary" type="submit">
                      Add Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategory;
