import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { UpdateProductOptions } from "../../../redux/product/ProductSlice";

const OptionsBulkUploade = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas, "datas");
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    for (let i in data) {
      let myrow = data[i];
      numbers = i;
      let opntionOneName = "";
      let Option1Option1 = "";

      let opntionTwoName = "";
      let OptionTwoOption = "";

      let opntionThreeName = "";
      let OptionThreeOption = "";

      if (myrow.NameOption1 !== "NULL" && myrow.NameOption1 !== " ") {
        opntionOneName = myrow.NameOption1;
      }
      if (myrow.Option1 !== "NULL" && myrow.Option1 !== " ") {
        Option1Option1 = myrow.Option1;
      }

      if (myrow.NameOption2 !== "NULL" && myrow.NameOption2 !== " ") {
        opntionTwoName = myrow.NameOption2;
      }
      if (myrow.Option2 !== "NULL" && myrow.Option2 !== " ") {
        OptionTwoOption = myrow.Option2;
      }

      if (myrow.NameOption3 !== "NULL" && myrow.NameOption3 !== " ") {
        opntionThreeName = myrow.NameOption3;
      }
      if (myrow.Option3 !== "NULL" && myrow.Option3 !== " ") {
        OptionThreeOption = myrow.Option3;
      }

      const formData = {
        ProductId: myrow.ProductId,

        optionNameOne: opntionOneName,
        optionValueOne: Option1Option1,

        optionNameTwo: opntionTwoName,
        optionValueTwo: OptionTwoOption,

        optionNameThree: opntionThreeName,
        optionValueThree: OptionThreeOption,

        // optionNameFour: myrow.PackSize,
        // optionValueFour: myrow.PackSize,

        thumbnail: `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Desktop/${myrow.ImgUrlDesk}`,
        icon: `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Mobile/${myrow.ImgUrlMbl}`,
        desktopicon: `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Desktop/${myrow.ImgUrlDesk}`,
        productSlider: [
          `https://mystoreewshooping.mygoldenhomes.com/admin/Products/Desktop/${myrow.ImgUrlDesk}`,
        ],
        CostPrc: 0,
        GstCost: 18,
        SellingPrice: myrow.SellingPrice,
        GstSellPrc: 18,
        prime: true,
        Mrp: myrow.Mrp,
        Discount: myrow.Discount,
      };
      const packupdate = await dispatch(UpdateProductOptions(formData));
      console.log(packupdate.payload, "packupdate.payload");
      if (packupdate.payload.success) {
        setUploadedProductName(`${numbers}) ${myrow.Name}  upload successfull`);
      }
    }
  };
  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Product Options Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3">Upload Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Product Options Bulk Upload
                  </button>
                </Col>
              </Row>
              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OptionsBulkUploade;
