import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { read, utils, XLSX } from "xlsx";
import {
  groceryPost,
  validateGrocerySlugUrl,
} from "../../../redux/grocery/groceySlice";

import {
  groceryBulkBanner,
  groceryBulkDesktop,
  groceryBulkIcon,
  groceryBulkimag,
  groceryBulkThumbnail,
  latesuplodeBanner,
  latesuplodedesktop,
  latesuplodeIcons,
  latesuplodeThumbnails,
} from "../../../redux/GroceryBulkUpload/GroceryBulkUploadSlice";
import { CSVLink } from "react-csv";


const GroceryBulkUpload = () => {

  
  const {
    groceryicon,
    grocerythumb,
    grocerybanner,
    grocerydesktop,
    isgroceryIconLoading,
    isgrocerythumbLoading,
    isgrocerybanLoading,
    isgrocerydeskLoading,
    latestImageresorces,
  } = useSelector((store) => store.groceryBulk);
  const { grocerycategory, categorytotal } = useSelector(
    (store) => store.categoryAll
  );
  const { subcategorytotal } = useSelector((store) => store.subCategoryAll);
  const { grocerysuperCat, superCat } = useSelector(
    (store) => store.superCategory
  );
  const { grocerytotal } = useSelector((store) => store.groceryAll);

  const {
    groceryimageresorces,
    isLoading,
    latestImageresorcesIcon,
    latestImageresorcesThumnail,
    latestImageresorcesDesktop,
    latestImageresorcesBanner,
  } = useSelector((store) => store.groceryBulk);

      const [loadData, setLoadData] = useState([]);
      useEffect(() => {
        loadList();
      }, []);

      const loadList = () => {
        setLoadData(groceryicon);
      };

  const [iconFamily, setIconFamily] = useState("");
  const [thumnailFamily, setthumnailFamily] = useState("");
  const [bannerFamily, setBannerFamily] = useState("");
  const [desktopFamily, setDesktopFamily] = useState("");

  const [banner, setBanner] = useState([]);
  const [icon, setIcon] = useState([]);
  const [thumnnail, setThumbnail] = useState([]);
  const [desktop, setDesktop] = useState([]);

  const [supcategory, setSupCategory] = useState("");
  const [supcategoryid, setSupCategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");

  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [errorslug, setErrorslug] = useState("");
  const [suceessmassage, setSuceessmassage] = useState("");

  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const selectSupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupCategoryid(value);
    setSupCategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
    const catbysupcat = categorytotal.filter(
      (supcat) => supcat.superCategoryId === supcategoryid
    );
    setCategories(catbysupcat);
  }, [supcategoryid, categoryid]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const iconChange = (e) => {
    let newicons = [];
    setIcon(newicons);
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          let newicons = icon;
          newicons.push(reader.result);
          setIcon(newicons);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const thumbnailChange = (e) => {
    const formData = {
      Familyname: thumnailFamily,
      image: grocerybanner,
    };
    let newthumnail = [];
    setThumbnail(newthumnail);
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          let newthumnail = thumnnail;
          newthumnail.push(reader.result);
          setThumbnail(newthumnail);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const bannerChange = (e) => {
    let newbanners = [];
    setBanner(newbanners);
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          let newbanners = banner;
          newbanners.push(reader.result);
          setBanner(newbanners);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const desktopChange = (e) => {
    let newdesktop = [];
    setDesktop(newdesktop);
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          let newdesktop = desktop;
          newdesktop.push(reader.result);
          setDesktop(newdesktop);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const bannerClick1 = async (e) => {
    for (let i = 0; i < banner.length; i++) {
      const formData = {
        Familyname: bannerFamily,
        banner: banner[i],
      };
      const groceban = await dispatch(groceryBulkimag(formData));
      if (!groceban) {
        <div></div>;
      } else {
        if (groceban.payload.success) {
          alert("Image Added");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    }

    for (let i = 0; i < icon.length; i++) {
      const formData = {
        Familyname: iconFamily,
        icon: icon[i],
      };
      const groceicon = await dispatch(groceryBulkimag(formData));
    }

    for (let i = 0; i < thumnnail.length; i++) {
      const formData = {
        Familyname: thumnailFamily,
        thumbnail: thumnnail[i],
      };
      dispatch(groceryBulkimag(formData));
    }

    for (let i = 0; i < desktop.length; i++) {
      const formData = {
        Familyname: desktopFamily,
        desktopicon: desktop[i],
      };
      dispatch(groceryBulkimag(formData));
    }
  };
  const bannerClick = async (e) => {
    for (let i = 0; i < banner.length; i++) {
      const formData = {
        Familyname: bannerFamily,
        banner: banner[i],
      };
      const groceban = await dispatch(groceryBulkBanner(formData));
      dispatch(latesuplodeBanner(formData));

      if (!groceban) {
        <div></div>;
      } else {
        if (groceban.payload.success) {
          alert("banner Added");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    }
  };
  const iconClick = async (e) => {
    for (let i = 0; i < icon.length; i++) {
      const formData = {
        Familyname: iconFamily,
        icon: icon[i],
      };
      const groceicon = await dispatch(groceryBulkIcon(formData));
      dispatch(latesuplodeIcons(formData));
    }
  };
  const thumnailClick = async (e) => {
    for (let i = 0; i < thumnnail.length; i++) {
      const formData = {
        Familyname: thumnailFamily,
        thumbnail: thumnnail[i],
      };
      dispatch(groceryBulkThumbnail(formData));
      dispatch(latesuplodeThumbnails(formData));
    }
  };
  const desktopClick = async (e) => {
    for (let i = 0; i < desktop.length; i++) {
      const formData = {
        Familyname: desktopFamily,
        desktopicon: desktop[i],
      };
      dispatch(groceryBulkDesktop(formData));
      dispatch(latesuplodedesktop(formData));
    }
  };

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    setData(datas);
  };
  
  const handlesubmit = async (e) => {
    for (let i in data) {
      let myrow = data[i];

      let slugurl = myrow.PRODUCTNAME
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
        .replaceAll(/[&\/\\#,+()$~%.'/":*?<>{}]/g, "-")
        .replaceAll("----", "-")
        .replaceAll("---", "-")
        .replaceAll("--", "-");

      if (slugurl != "") {
        try {
          let responce = await dispatch(validateGrocerySlugUrl(slugurl));
          if (responce.payload.success) {
            setErrorslug(` ${myrow.PRODUCTNAME} alredy exist`);
            alert(` ${myrow.PRODUCTNAME} alredy exist`);
          } else {
            setCheckSlugUrl(slugurl);
            setErrorslug("");
          }
        } catch (error) {
          setCheckSlugUrl(slugurl);
          setErrorslug("");
        }
      }

      let discount = Math.round( ((myrow.MRP - myrow.SELLINGPRICE) * 100) / myrow.MRP);
      setSuceessmassage("");
      if (category !== "" && subcategory !== "") {
        const formData = {
          name: myrow.PRODUCTNAME.charAt(0).toUpperCase() + myrow.PRODUCTNAME.slice(1).toLowerCase(),
          slugUrl: slugurl,
          superCategory: grocerysuperCat.name,
          superCategoryId: grocerysuperCat._id,
          category: category,
          categoryId: categoryid,
          subCategory: subcategory,
          subCategoryId: subcategoryid,
          brand: myrow.BRAND,
          hsnCode: myrow.HSNCODE,
          options: [
            {
              optionName: myrow.PACKSIZE,
              barcode: myrow.BARCODE,
              thumbnail: myrow.THUMBNAIL,
              icon: myrow.ICON,
              desktopicon: myrow.DESKTOP,
              productSlider: myrow.SLIDER.replaceAll(" ", "").split(","),
              costPrice: myrow.PURCHASEPRICE,
              sellingPrice: myrow.SELLINGPRICE,
              mrp: myrow.MRP,
              gstCost: myrow.GSTCOST,
              gstSellPrice: myrow.GSTSELLINGPRICE,
              discounts: discount,
            },
          ],
        };
        const grocerybulk = await dispatch(groceryPost(formData));

        if (grocerybulk.payload.success) {
          setSuceessmassage(`${myrow.PRODUCTNAME}  upload successfull`);
        }
      } else {
        alert("please provide valid details");
      }
    }
  };

  const columnsthumb = [
    {
      field: "Familyname",
      headerName: " NAME",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: " IMAGE URL",
      width: 770,
      headerAlign: "center",
      align: "start",
    },

    {
      headerName: "IMAGE",
      width: 180,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.image}
              width={60}
              alt="desktopBanner"
            />
          </>
        );
      },
    },
  ];
  const columnsbanner = [
    {
      field: "Familyname",
      headerName: " NAME",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: " IMAGE URL",
      width: 770,
      headerAlign: "center",
      align: "start",
    },

    {
      headerName: "IMAGE",
      width: 180,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.image}
              width={60}
              alt="desktopBanner"
            />
          </>
        );
      },
    },
  ];
  const columnsicon = [
    {
      field: "Familyname",
      headerName: " NAME",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: " IMAGE URL",
      width: 770,
      headerAlign: "center",
      align: "start",
    },

    {
      headerName: "IMAGE",
      width: 180,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.image}
              width={60}
              alt="desktopBanner"
            />
          </>
        );
      },
    },
  ];
  const columnsdesktop = [
    {
      field: "Familyname",
      headerName: " NAME",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: " IMAGE URL",
      width: 770,
      headerAlign: "center",
      align: "start",
    },

    {
      headerName: "IMAGE",
      width: 180,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.image}
              width={60}
              alt="desktopBanner"
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form>
              <h4 className="p-4">Bulk image Upload</h4>
              <div className="card m-4">
                <div className="card-footer d-flex justify-content-between">
                  <h5>Bulk Product Upload</h5>
                  {/* <CSVLink data={loadData} onClick={() => {}}> */}
                    <a className="btn btn-success" href="../excel.xls" download={true}
                    >export data</a>
                  {/* </CSVLink> */}
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Icon Family *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={iconFamily}
                        onChange={(e) => setIconFamily(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Icon</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={iconChange}
                        multiple
                      />
                      <p>* The image width and height should be 45px * 45px</p>
                    </Col>

                    <Col md>
                      <button
                        className="btn btn-primary me-2 mt-4 ml-5 "
                        onClick={(e) => iconClick(e)}
                        type="submit"
                      >
                        Add Icon
                      </button>
                    </Col>
                  </Row>
                  <Row className="g-2 d-flex flex-wrap m-2">
                    <div>
                      {isgroceryIconLoading ? (
                        <div></div>
                      ) : (
                        <div>
                          {groceryicon.map((data, index) => (
                            <img
                              src={data}
                              key={index}
                              width={150}
                              height={150}
                              alt="img"
                              className="m-2"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </Row>
                  {/* <div
                    style={{ height: 325, width: "100%" }}
                    className="d-flex m-auto text-align-center"
                  >
                    <DataGrid
                      rows={latestImageresorcesIcon}
                      columns={columnsicon}
                      getRowId={(row) => row._id}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                    />
                  </div> */}
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Thumbnail Family *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={thumnailFamily}
                        onChange={(e) => setthumnailFamily(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Thumbnail</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={thumbnailChange}
                        multiple
                      />
                      <p>
                        * The image width and height should be 150px * 150px
                      </p>
                      <div>
                        {isgrocerythumbLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {grocerythumb.map((data, index) => (
                              <img
                                src={data}
                                key={index}
                                width={150}
                                height={150}
                                alt="img"
                                className="mx-2"
                              />
                            ))}{" "}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col md>
                      <button
                        className="btn btn-primary me-2 mt-4 ml-5 "
                        onClick={(e) => thumnailClick(e)}
                        type="submit"
                      >
                        Add Thumbnail
                      </button>
                    </Col>
                  </Row>
                  {/* <div
                    style={{ height: 325, width: "100%" }}
                    className="d-flex m-auto text-align-center"
                  >
                    <DataGrid
                      rows={latestImageresorcesThumnail}
                      columns={columnsthumb}
                      getRowId={(row) => row._id}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                    />
                  </div> */}
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Slider Family *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={bannerFamily}
                        onChange={(e) => setBannerFamily(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Product Slider</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={bannerChange}
                        multiple
                      />
                      <p>
                        * The image width and height should be 600px * 600px
                      </p>
                      <div>
                        {isgrocerybanLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {grocerybanner.map((data, index) => (
                              <img
                                src={data}
                                key={index}
                                width={150}
                                height={150}
                                alt="img"
                                className="mx-2"
                              />
                            ))}{" "}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col md>
                      <button
                        className="btn btn-primary me-2 mt-4 ml-5 "
                        onClick={(e) => bannerClick(e)}
                        type="submit"
                      >
                        Add Product Slider
                      </button>
                    </Col>
                  </Row>
                  {/* <div
                    style={{ height: 325, width: "100%" }}
                    className="d-flex m-auto text-align-center"
                  >
                    <DataGrid
                      rows={latestImageresorcesBanner}
                      columns={columnsbanner}
                      getRowId={(row) => row._id}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                    />
                  </div> */}
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Desktop Icon Family *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={desktopFamily}
                        onChange={(e) => setDesktopFamily(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Desktop Icon</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={desktopChange}
                        multiple
                      />
                      <p>* The image width and height should be 75px * 75px</p>
                      {/* <div>
                        {isgrocerydeskLoading ? (
                          <div></div>
                        ) : (
                          <div>
                            {grocerydesktop &&
                              grocerydesktop.map((data, index) => (
                                <img
                                  src={data}
                                  key={index}
                                  width={150}
                                  height={150}
                                  alt="img"
                                  className="mx-2"
                                />
                              ))}{" "}
                          </div>
                        )}
                      </div> */}
                    </Col>

                    <Col md>
                      <button
                        className="btn btn-primary me-2 mt-4 ml-5 "
                        onClick={(e) => desktopClick(e)}
                        type="submit"
                      >
                        Add Desktop Icon
                      </button>
                    </Col>
                  </Row>
                  {/* <div
                    style={{ height: 325, width: "100%" }}
                    className="d-flex m-auto text-align-center"
                  >
                    <DataGrid
                      rows={latestImageresorcesDesktop}
                      columns={columnsdesktop}
                      getRowId={(row) => row._id}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                    />
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Bulk Product Upload</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Bulk Product Upload</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSupCategory(e);
                        }}
                        value={superCat._id}
                        name={superCat.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {superCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categories._id}
                        name={categories.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categories.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Upload Excel File *
                      </Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="enter name....."
                        onChange={(e) => handlefile(e)}
                      />
                      <p style={{ color: "green" }} className="mt-2">
                        {suceessmassage}
                      </p>
                      <p style={{ color: "red" }} className="mt-2">
                        {errorslug}
                      </p>
                    </Col>
                    <Col md>
                      <button
                        className="btn btn-primary me-2 mt-4 ml-5 "
                        type="submit"
                      >
                        Bulk Upload Data
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default GroceryBulkUpload;
