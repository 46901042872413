import { configureStore } from "@reduxjs/toolkit";
import superCategoryReducer from "./redux/superCategory/superCategorySlice";
import categoryReducer from "./redux/category/categorySlice";
import brandReducer from "./redux/brand/brandSlice";
import subCategoryReducer from "./redux/subCategory/subCategorySlice";
import groceryAllReducer from "./redux/grocery/groceySlice";
import authSlice from "./redux/Athentication/AuthSlice";
import groceryBulkReducer from "./redux/GroceryBulkUpload/GroceryBulkUploadSlice";
import adminReducer from "./redux/admin/AdminSlice";
import ProductReducer from "./redux/product/ProductSlice";
import SlidersReducer from "./redux/slider/sliderSlice";
import ordersReducer from "./redux/order/orderSlice";
import shopReducer from "./redux/shop/ShopSlice";

export const store = configureStore({
  reducer: {
    superCategory: superCategoryReducer,
    categoryAll: categoryReducer,
    subCategoryAll: subCategoryReducer,
    brandAll: brandReducer,
    groceryAll: groceryAllReducer,
    Product: ProductReducer,
    groceryBulk: groceryBulkReducer,
    admin: adminReducer,
    Sliders: SlidersReducer,
    orders: ordersReducer,
    shop: shopReducer,
    auth: authSlice.reducer,
  },
});
