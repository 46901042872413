import React from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {superCategoryDelete} from "../../../redux/superCategory/superCategorySlice";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";

const ListSuperCategory = () => {
  
  const { superCat } = useSelector((store) => store.superCategory);
  const [loadData, setLoadData] = useState([]);

  useEffect(() => {
    loadList();
  }, []);

  const loadList = () => {
    setLoadData(superCat);
  };

  const dispatch = useDispatch();
  const deleteClick = async (e, id) => {
    const data = await dispatch(superCategoryDelete(id));

    // if (data.payload.success) {
    //   alert("Category Deleted");
    //   window.location.reload(false);
    // } else {
    //   alert(data.message);
    //   console.log(data.payload.error);
    // }
  };
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   width: 250,
    //   headerAlign: "center",
    // },
    {
      field: 'id' , 
      headerName: 'S. No', 
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align:"center",
      headerAlign: "center",
    },
    
    {
      field: "name",
      headerName: "SUPER CATEGORY NAME",
      width: 280,
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 270,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail.url}
    //           width={50}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      field: "ACTION",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
          <Link to={`/admin/updatesupercategory/${params.row.slugUrl}`}>
            <Button  variant="contained" color="primary">
              EDIT
            </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            {/* <Button variant="contained" color="error">
              DELETE
            </Button> */}
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
          <div className="content-wrapper ">
          <div className="card-footer">
            <div className="mb-3">
              <CSVLink
                data={loadData}
                onClick={() => {}}
              >
                <button className="btn btn-success mt-3">export data</button>
              </CSVLink>
            </div>
          </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid 
                rows={superCat}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
              />
            </div>
          </div>
      </Fragment>
    </>
  );
};

export default ListSuperCategory;
